import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

import type { ReactNode } from "react";

export const ActionButtonsView = function ActionButtonsView({
  children,
}: {
  children: ReactNode;
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "sticky",
        width: "100%",
        bottom: 0,
        borderTop: "1px solid",
        borderColor: "#e7e5f5",
        ...theme.applyStyles("dark", {
          borderColor: "#333",
        }),
        padding: 2,
        backgroundColor: "background.default",
        zIndex: 2,
      }}
    >
      {children ? children : <div />}
    </Box>
  );
};

export const ActionButtons = function ActionButtons({
  cancel,
  children,
  isSaving = false,
  submitText = "Submit",
}: {
  cancel: () => void;
  children?: ReactNode;
  isSaving?: boolean;
  submitText?: string;
}) {
  return (
    <ActionButtonsView>
      {children ? children : <div />}
      <Button
        color="primary"
        loading={isSaving}
        sx={{
          marginRight: 1,
          minWidth: "7rem",
        }}
        type="submit"
        variant="contained"
      >
        {submitText}
      </Button>
      <Button
        color="neutral"
        onClick={cancel}
        sx={{
          marginRight: 2,
          minWidth: "7rem",
        }}
        type="button"
        variant="outlined"
      >
        Cancel
      </Button>
    </ActionButtonsView>
  );
};
