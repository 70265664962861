import "./index.css";

import { useLogin } from "@kaplan-labs/up-auth-api-client";
import { useEffect, useRef, useState } from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CachedIcon from "@mui/icons-material/Cached";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import clientRoutes from "@/lib/client-routes";

import { DecryptedText } from "./DecryptedText";

const COLORS = ["#FFD980", "#FF8480", "#81FF80", "#80EAFF"];

function Background() {
  return (
    <div className="login-form-grid">
      {[...Array(3000)].map((_, i) => (
        <Cell key={i} />
      ))}
    </div>
  );
}

function Cell() {
  const [tempActive, setTempActive] = useState(false);
  const color = COLORS[Math.floor(Math.random() * COLORS.length)];

  return (
    <div
      onMouseEnter={() => {
        setTempActive(true);
        setTimeout(() => setTempActive(false), 1000);
      }}
      style={{
        width: "2rem",
        height: "2rem",
        borderWidth: "1px",
        background: tempActive ? `${color}1a` : "#222",
        border: `solid ${tempActive ? `1px ${color}` : "0.5px #333"}`,
      }}
    />
  );
}

export default function Login() {
  const emailRef = useRef(null);
  const {
    activeField,
    email,
    emailErrorMessage,
    isLoading,
    otpErrorMessage,
    onSubmit,
    onChange,
    reset,
  } = useLogin({
    onSuccess($auth) {
      const uri = $auth.uriBeforeFailure;
      window.location.href =
        !uri || uri.match(/login/) ? clientRoutes.inventorySearch.index() : uri;
    },
  });

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const FF =
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

  return (
    <div
      className="animation-fade-in-slow"
      style={{
        position: "absolute",
        inset: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        opacity: 0,
      }}
    >
      <Background />
      <Box className="login-form" as="form" onSubmit={onSubmit}>
        <Box
          as="div"
          sx={{
            position: "relative",
            flexDirection: "column",
            justifyContent: "center",
            zIndex: 2,
            padding: "2rem",
            width: "420px",
          }}
        >
          <Typography
            sx={{
              mb: 3,
              fontWeight: 700,
              letterSpacing: "-0.045rem",
              lineHeight: 1.1,
              fontFamily: FF,
              textShadow:
                "0 0 40px rgb(145, 52, 246), 0 2px 10px rgba(0, 0, 0, 0.5)",
            }}
            variant="h2"
          >
            <DecryptedText
              text="Back Office"
              animateOn="view"
              speed={125}
              sequential
              useOriginalCharsOnly
            />
          </Typography>
          {activeField === "email" && (
            <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
              <input
                type="email"
                name="email"
                placeholder="Email address"
                onChange={onChange}
                ref={emailRef}
              />
              <Button
                color="inherit"
                loading={isLoading}
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(255,255,255, 0.15)",
                    boxShadow: "none",
                  },
                  "&:disabled": {
                    backgroundColor: "rgba(255,255,255, 0.15)",
                  },
                  "& .MuiButton-loadingIndicator": {
                    color: "#fff",
                  },
                  color: "#fff",
                  fontFamily: FF,
                  backgroundColor: "rgba(255,255,255, 0.15)",
                }}
              >
                <ArrowForwardIcon />
              </Button>
            </Box>
          )}

          {activeField === "otp" ? (
            <>
              <div
                className="animation-fade-in"
                style={{
                  color: "#fff",
                  fontFamily: FF,
                }}
              >
                <DecryptedText
                  text="Check your email for a single-use password"
                  animateOn="view"
                  sequential
                  useOriginalCharsOnly
                />
              </div>
              <div
                className="animation-fade-in"
                style={{
                  fontFamily: FF,
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "2rem",
                  gap: "0.75rem",
                  color: "#fff",
                }}
              >
                <DecryptedText
                  text={email}
                  animateOn="view"
                  sequential
                  useOriginalCharsOnly
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    reset();
                  }}
                  type="button"
                  style={{
                    border: 0,
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontFamily: FF,
                    fontSize: "0.8rem",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "0.25rem",
                    color: "#fff",
                    opacity: 0.75,
                  }}
                >
                  <CachedIcon />
                  restart login
                </button>
              </div>
              <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                <input
                  disabled={activeField !== "otp"}
                  type="text"
                  name="otp"
                  placeholder="Single-use password"
                  onChange={onChange}
                />
                <Button
                  color="inherit"
                  loading={isLoading}
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(255,255,255, 0.15)",
                      boxShadow: "none",
                    },
                    "&:disabled": {
                      backgroundColor: "rgba(255,255,255, 0.15)",
                    },
                    "& .MuiButton-loadingIndicator": {
                      color: "#fff",
                    },
                    color: "#fff",
                    fontFamily: FF,
                    backgroundColor: "rgba(255,255,255, 0.15)",
                  }}
                >
                  <ArrowForwardIcon />
                </Button>
              </Box>
            </>
          ) : null}

          <input type="hidden" name="domain" value={window.location.href} />

          <div>
            {emailErrorMessage ? (
              <div className="animation-fade-in">{emailErrorMessage}</div>
            ) : null}
            {otpErrorMessage ? (
              <div className="animation-fade-in">
                {otpErrorMessage}. Please try again.
              </div>
            ) : null}
          </div>
        </Box>
      </Box>
    </div>
  );
}
