import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import PublishIcon from "@mui/icons-material/Publish";
import Button from "@mui/material/Button";

import UPDialog from "@/components/UPDialog";

const ConfirmationDialog = ({ openDialog, handleClose, mutation }) => {
  const mutationMap = {
    cancel: {
      icon: DoDisturbIcon,
      title: "Are you sure you want to permanently cancel this inventory?",
      buttonColor: "error",
      buttonText: "Yes, Cancel Inventory",
    },
    promote: {
      icon: PublishIcon,
      title: "Are you sure you want to promote this inventory item to Market?",
      buttonColor: "primary",
      buttonText: "Yes, Promote Inventory to Market",
    },
    migrate: {
      icon: PublishIcon,
      title: "Are you sure you want to create this inventory item in Canvas?",
      buttonColor: "primary",
      buttonText: "Yes, Create Item in Canvas",
    },
  }[openDialog];
  return openDialog ? (
    <UPDialog
      close={handleClose}
      Content="You cannot undo this action."
      Icon={mutationMap.icon}
      Title={mutationMap.title}
      Actions={
        <>
          <Button color="neutral" onClick={handleClose} variant="outlined">
            Never mind
          </Button>
          <Button
            color={mutationMap.buttonColor}
            onClick={() => {
              mutation.mutate();
            }}
            variant="contained"
            loading={mutation.isPending}
          >
            {mutationMap.buttonText}
          </Button>
        </>
      }
    />
  ) : null;
};

export default ConfirmationDialog;
