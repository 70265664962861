import { useMutation, useQuery } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { ActionButtonsView } from "@/components/ActionButtons";
import { FORM_PAPER_SX } from "@/constants";
import { useAlert } from "@/hooks/use-alert";
import Enrollment from "@/models/enrollment";
import Mentor from "@/models/mentor";
import {
  useMentorsAssignedToInventoryItem,
  useMentorsByCatalogueItemID,
} from "@/models/mentor/hooks";

import Assign from "./Assign";
import AssignedMentorTableRow from "./AssignedMentorTableRow";
import MentorsTableEmptyContent from "./MentorsTableEmptyContent";

const AnimatedTableRow = motion(AssignedMentorTableRow);

export default function MentorAssignmentForm(props) {
  const { inventoryItem } = props;

  const showAlert = useAlert();

  const qualifiedMentors = useMentorsByCatalogueItemID(
    inventoryItem.catalogueItem._id,
  );

  const sections = useQuery({
    queryKey: Enrollment.fetchLmsSections.queryKey({
      partnerId: inventoryItem.partnerId,
      inventoryId: inventoryItem._id,
    }),
    queryFn() {
      return Enrollment.fetchLmsSections.queryFn({
        partnerId: inventoryItem.partnerId,
        inventoryId: inventoryItem._id,
      });
    },
    retry: 3,
  });

  const assignedMentors = useMentorsAssignedToInventoryItem(inventoryItem._id);

  const assignMutation = useMutation({
    mutationFn(data, email) {
      return Mentor.assign(data, email);
    },
    onSuccess() {
      assignedMentors.refetch();
    },
    onError() {
      showAlert({
        message: "An Error Has Occurred",
        severity: "error",
      });
    },
  });

  const handleAssign = (data, email) => {
    assignMutation.mutate({
      data,
      email,
    });
  };

  const NO_MENTORS =
    !assignedMentors.isLoading && assignedMentors.data?.length === 0;

  const IS_LOADING =
    qualifiedMentors.isLoading ||
    assignedMentors.isLoading ||
    sections.isLoading;

  return (
    <div>
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        {IS_LOADING ? (
          <div>Loading data...</div>
        ) : sections.isSuccess ? (
          <Assign
            mentors={
              qualifiedMentors.data?.filter((qualifiedMentor) => {
                return !assignedMentors.data?.find((assignedMentor) => {
                  return qualifiedMentor.email === assignedMentor.email;
                });
              }) || []
            }
            isSaving={assignMutation.isPending}
            submit={(email) => {
              handleAssign(inventoryItem, email);
            }}
          />
        ) : (
          <Typography variant="body1">Canvas course not found</Typography>
        )}

        <br />

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                {assignedMentors.data?.length
                  ? "Mentor"
                  : "No Mentors Assigned"}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody className={NO_MENTORS ? "empty-table-body" : ""}>
            {assignedMentors.isLoading ? (
              <MentorsTableEmptyContent animation="wave" />
            ) : null}
            {NO_MENTORS ? <MentorsTableEmptyContent animation={false} /> : null}
            <AnimatePresence>
              {assignedMentors.data?.map((mentor) => {
                return (
                  <AnimatedTableRow
                    key={mentor._id}
                    mentor={mentor}
                    sections={sections}
                    inventoryItem={inventoryItem}
                    onError={() => {
                      showAlert({
                        message: "An Error Has Occurred",
                        severity: "error",
                      });
                    }}
                    onSuccess={() => {
                      assignedMentors.refetch();
                    }}
                    unassign={() => {
                      return Mentor.unassign({
                        data: inventoryItem,
                        email: mentor.email,
                      });
                    }}
                    transition={{
                      ease: [0.76, 0, 0.24, 1],
                      duration: 0.4,
                    }}
                    animate={{
                      opacity: 1,
                    }}
                    exit={
                      assignedMentors.data?.length > 1
                        ? {
                            opacity: 0,
                          }
                        : null
                    }
                    style={{
                      opacity: 0,
                    }}
                  />
                );
              })}
            </AnimatePresence>
          </TableBody>
        </Table>
      </Paper>

      <ActionButtonsView>
        <Button
          color="neutral"
          onClick={props.cancel}
          type="button"
          variant="outlined"
        >
          Close
        </Button>
      </ActionButtonsView>
    </div>
  );
}
