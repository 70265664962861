import { signOut } from "@kaplan-labs/up-auth-api-client";
import { AnimatePresence, motion } from "framer-motion";
import {
  Activity,
  Bank,
  ChartLineUp,
  CurrencyDollar,
  HourglassMedium,
  ListBullets,
  ListChecks,
  MagnifyingGlass,
  Newspaper,
  Notebook,
  ShoppingCart,
  SignOut,
  Sliders,
  Student,
  Ticket,
} from "phosphor-react";
import { useMemo } from "react";
import { Link, useLocation } from "react-router";

// Material UI
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

// KUP
import { useDrawer } from "@/components/Drawer";
import { NAVIGATION_DRAWER_WIDTH } from "@/constants";
import clientRoutes from "@/lib/client-routes";

import { NavList } from "./NavList";
import AiDrawer from "../Drawers/AiDrawer";

const subNavAnimationProps = {
  initial: { height: 0, opacity: 0 },
  animate: { height: "auto", opacity: 1 },
  exit: { height: 0, opacity: 0 },
  style: { overflow: "hidden" },
};

const NavItem = function ({
  icon,
  route,
  selected,
  text,
  size = "base",
}: {
  icon?: React.ReactNode;
  route: string;
  selected?: boolean;
  text: string;
  size?: "base" | "small";
}) {
  return (
    <ListItem component={Link} disablePadding to={route}>
      <ListItemButton
        className={icon ? "" : "sub-nav"}
        selected={selected}
        sx={(theme) => ({
          "&.Mui-selected": {
            color: theme.palette.text.primary,
          },
        })}
      >
        {icon ? (
          <ListItemIcon
            sx={{
              color: (theme) => {
                return theme.palette.primary.main;
              },
              minWidth: "30px",
            }}
          >
            {icon}
          </ListItemIcon>
        ) : (
          <Box sx={{ minWidth: "30px" }} />
        )}
        <ListItemText
          primary={text}
          slotProps={{
            primary: {
              fontSize: size === "base" ? "16px" : "14px",
            },
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

const ICON_CLASS = "MuiSvgIcon-root";
const ICON_SIZE = "22";

export const NavigationDrawer = function NavigationDrawer({
  open = false,
  setOpen,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
}) {
  const currentLocation = useLocation();
  const currentPathname = currentLocation.pathname;
  const aiDrawer = useDrawer(AiDrawer);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const drawer = useMemo(() => {
    const isSelected = function (_href) {
      const href = _href.replace(/^\//, "");
      return Boolean(currentPathname.match(`^/${href}`));
    };

    const close = function () {
      setOpen(false);
    };

    return (
      <Stack
        data-test="navigation-drawer"
        onClick={close}
        sx={{ flexGrow: 1, bgcolor: "nav.main" }}
      >
        <Toolbar>
          <Typography component="div" sx={{ flexGrow: 1, fontWeight: 500 }}>
            Back Office
            {import.meta.env.VITE_ENVIRONMENT !== "production" ? (
              <div
                className={
                  import.meta.env.VITE_ENVIRONMENT === "preview"
                    ? "testing-badge"
                    : null
                }
              >
                <code
                  style={{
                    position: "relative",
                    color: "#111",
                    fontSize: "11px",
                    border: "1px solid #666",
                    borderRadius: "1rem",
                    padding: "0.15rem 0.6rem",
                    backgroundColor: "#fff",
                    opacity: 0.7,
                    cursor: "default",
                  }}
                >
                  {import.meta.env.VITE_ENVIRONMENT === "preview"
                    ? "Test"
                    : "Dev"}{" "}
                  Environment
                </code>
              </div>
            ) : null}
          </Typography>
          <button
            onClick={aiDrawer.show}
            style={{
              position: "relative",
              cursor: "pointer",
              border: "none",
              background: "none",
              right: "-10px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 256 256"
              fill="#555"
            >
              <path
                fill="url(#mason-fill)"
                d="M96 140a12 12 0 1 1-12-12 12 12 0 0 1 12 12m76-12a12 12 0 1 0 12 12 12 12 0 0 0-12-12m60-80v88c0 52.93-46.65 96-104 96S24 188.93 24 136V48a16 16 0 0 1 27.31-11.31c.14.14.26.27.38.41L69 57a111.22 111.22 0 0 1 118.1 0l17.21-19.9c.12-.14.24-.27.38-.41A16 16 0 0 1 232 48m-16 0-21.56 24.8a8 8 0 0 1-10.81 1.2A89 89 0 0 0 168 64.75V88a8 8 0 1 1-16 0V59.05a97.4 97.4 0 0 0-16-2.72V88a8 8 0 1 1-16 0V56.33a97.4 97.4 0 0 0-16 2.72V88a8 8 0 1 1-16 0V64.75A89 89 0 0 0 72.37 74a8 8 0 0 1-10.81-1.17L40 48v88c0 41.66 35.21 76 80 79.67v-20.36l-13.66-13.66a8 8 0 0 1 11.32-11.31L128 180.68l10.34-10.34a8 8 0 0 1 11.32 11.31L136 195.31v20.36c44.79-3.69 80-38 80-79.67Z"
              />
            </svg>
          </button>
        </Toolbar>
        <NavList>
          <NavItem
            icon={<MagnifyingGlass className={ICON_CLASS} size={ICON_SIZE} />}
            route={clientRoutes.inventorySearch.index()}
            selected={isSelected(clientRoutes.inventorySearch.root)}
            text="Inventory"
          />

          <NavItem
            icon={<Student className={ICON_CLASS} size={ICON_SIZE} />}
            route={clientRoutes.profiles.index()}
            selected={isSelected(clientRoutes.profiles.root)}
            text="Profiles"
          />

          <NavItem
            icon={<Bank className={ICON_CLASS} size={ICON_SIZE} />}
            route={clientRoutes.partners.index()}
            selected={isSelected(clientRoutes.partners.root)}
            text="Partners"
          />

          <NavItem
            icon={<Notebook className={ICON_CLASS} size={ICON_SIZE} />}
            route={clientRoutes.catalogue.index()}
            selected={isSelected(clientRoutes.catalogue.root)}
            text="Catalogue"
          />

          <NavItem
            icon={<Ticket className={ICON_CLASS} size={ICON_SIZE} />}
            route={clientRoutes.coupons.index()}
            selected={isSelected(clientRoutes.coupons.root)}
            text="Coupons"
          />

          <NavItem
            icon={<ShoppingCart className={ICON_CLASS} size={ICON_SIZE} />}
            route={clientRoutes.orders.index()}
            selected={isSelected(clientRoutes.orders.root)}
            text="Orders"
          />

          <NavItem
            icon={<ChartLineUp className={ICON_CLASS} size={ICON_SIZE} />}
            route={clientRoutes.insights.index()}
            selected={isSelected(clientRoutes.insights.root)}
            text="Insights"
          />
        </NavList>

        <NavList subheader={<ListSubheader>Mentors</ListSubheader>}>
          <NavItem
            icon={<MagnifyingGlass className={ICON_CLASS} size={ICON_SIZE} />}
            route={clientRoutes.mentorSearch.email.index()}
            text="Search"
          />
          <AnimatePresence>
            {isSelected(clientRoutes.mentorSearch.root) ? (
              <motion.div key="mentors-sub-nav" {...subNavAnimationProps}>
                <NavItem
                  route={clientRoutes.mentorSearch.email.index()}
                  selected={isSelected(clientRoutes.mentorSearch.email.index())}
                  text="Name/Email"
                  size="small"
                />
                <NavItem
                  route={clientRoutes.mentorSearch.catalogue.index()}
                  selected={isSelected(
                    clientRoutes.mentorSearch.catalogue.index(),
                  )}
                  text="Catalogue"
                  size="small"
                />
              </motion.div>
            ) : null}
          </AnimatePresence>
          <NavItem
            icon={<ListChecks className={ICON_CLASS} size={ICON_SIZE} />}
            route={clientRoutes.mentorAssignments.index()}
            selected={isSelected(clientRoutes.mentorAssignments.root)}
            text="Assignments"
          />
        </NavList>

        <NavList subheader={<ListSubheader>Inbox</ListSubheader>}>
          <NavItem
            icon={<Newspaper className={ICON_CLASS} size={ICON_SIZE} />}
            route={clientRoutes.applications.index("pendingReview")}
            text="Applications"
          />
          <AnimatePresence>
            {isSelected(clientRoutes.applications.root) && (
              <motion.div key="application-sub-nav" {...subNavAnimationProps}>
                <NavItem
                  route={clientRoutes.applications.index("pendingReview")}
                  selected={isSelected(
                    clientRoutes.applications.index("pendingReview"),
                  )}
                  text="Pending"
                  size="small"
                />
                <NavItem
                  route={clientRoutes.applications.index("onHoldCX")}
                  selected={isSelected(
                    clientRoutes.applications.index("onHoldCX"),
                  )}
                  text="On Hold: Agent"
                  size="small"
                />
                <NavItem
                  route={clientRoutes.applications.index("onHoldUser")}
                  selected={isSelected(
                    clientRoutes.applications.index("onHoldUser"),
                  )}
                  text="On Hold: User"
                  size="small"
                />
              </motion.div>
            )}
          </AnimatePresence>

          <NavItem
            icon={<CurrencyDollar className={ICON_CLASS} size={ICON_SIZE} />}
            route={clientRoutes.scholarships.index("pendingReview")}
            text="Scholarships"
          />
          <AnimatePresence>
            {isSelected(clientRoutes.scholarships.root) && (
              <motion.div key="scholarships-sub-nav" {...subNavAnimationProps}>
                <NavItem
                  route={clientRoutes.scholarships.index("pendingReview")}
                  selected={isSelected(
                    clientRoutes.scholarships.index("pendingReview"),
                  )}
                  text="Pending"
                  size="small"
                />
                <NavItem
                  route={clientRoutes.scholarships.index("onHoldCX")}
                  selected={isSelected(
                    clientRoutes.scholarships.index("onHoldCX"),
                  )}
                  text="On Hold: Agent"
                  size="small"
                />
                <NavItem
                  route={clientRoutes.scholarships.index("onHoldUser")}
                  selected={isSelected(
                    clientRoutes.scholarships.index("onHoldUser"),
                  )}
                  text="On Hold: User"
                  size="small"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </NavList>

        <NavList subheader={<ListSubheader>Marketing</ListSubheader>}>
          <NavItem
            icon={<ListBullets className={ICON_CLASS} size={ICON_SIZE} />}
            route={clientRoutes.marketingLists.index()}
            selected={isSelected(clientRoutes.marketingLists.root)}
            text="Lists"
          />
          <NavItem
            icon={<HourglassMedium className={ICON_CLASS} size={ICON_SIZE} />}
            route={clientRoutes.marketingLists.pending()}
            selected={isSelected(clientRoutes.marketingLists.pendingPath)}
            text="Import Status"
          />
        </NavList>

        <NavList subheader={<ListSubheader>Contacts</ListSubheader>}>
          <NavItem
            icon={<MagnifyingGlass className={ICON_CLASS} size={ICON_SIZE} />}
            route={clientRoutes.contacts.index()}
            selected={isSelected(clientRoutes.contacts.root)}
            text="Search"
          />
        </NavList>

        <div style={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ paddingBottom: 1 }}
        >
          <IconButton
            aria-label="System Status"
            component={Link}
            to={clientRoutes.status.index()}
          >
            <Activity className={ICON_CLASS} size={ICON_SIZE} />
          </IconButton>
          <IconButton
            aria-label="Settings"
            component={Link}
            to={clientRoutes.settings.index()}
          >
            <Sliders className={ICON_CLASS} size={ICON_SIZE} />
          </IconButton>
          <IconButton aria-label="Sign out" onClick={signOut}>
            <SignOut className={ICON_CLASS} size={ICON_SIZE} />
          </IconButton>
        </Stack>
      </Stack>
    );
  }, [currentPathname, setOpen]);

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      data-test="nav"
      sx={{ width: { md: NAVIGATION_DRAWER_WIDTH }, flexShrink: { md: 0 } }}
    >
      <Drawer
        container={container}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        onClose={handleDrawerToggle}
        open={open}
        sx={(theme) => ({
          display: { sm: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: NAVIGATION_DRAWER_WIDTH,
          },
          "& .MuiModal-root": { zIndex: theme.zIndex.drawer + 4 },
        })}
        variant="temporary"
      >
        {drawer}
      </Drawer>
      <Drawer
        open={true}
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: NAVIGATION_DRAWER_WIDTH,
          },
        }}
        variant="permanent"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
