import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";

import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { DrawerPortal } from "@/components/DrawerPortal";
import { NiceModal, useModal } from "@/components/NiceModal";

import type { CSSProperties, ReactNode, RefObject } from "react";

const supTitleStyle = {
  fontSize: "14px",
  fontWeight: 400,
  opacity: 0.8,
};

const progressStyle = {
  position: "absolute",
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
};

const DrawerCover = styled(motion.div)(() => ({
  position: "absolute",
  inset: 0,
  background: "#000",
  cursor: "pointer",
  zIndex: 3,
}));

const Sheet = styled(motion.div)(({ theme }) => ({
  overflowY: "auto",
  position: "fixed",
  top: "0.5rem",
  right: "0.5rem",
  bottom: "0.5rem",
  width: "calc(100% - 1rem)",
  maxWidth: "calc(100% - 1rem)",
  [theme.breakpoints.up("md")]: {
    width: "50%",
    maxWidth: "50%",
    minWidth: "525px",
  },
  height: "auto",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  borderRadius: "0.5rem",
  backgroundColor: "#f3f3f3",
  zIndex: theme.zIndex.modal - 1,
  ...theme.applyStyles("dark", {
    backgroundColor: "#111",
  }),
}));

export const DrawerManager = NiceModal;
export const useDrawer = useModal;

export const Drawer = function ({
  bodyRef,
  children,
  close,
  loading = false,
  title = "",
  supTitle = "",
  toolbarChildren,
  sheetStyle = {},
  sheetClassName = "",
}: {
  bodyRef?: RefObject<HTMLDivElement>;
  children: ReactNode;
  close?: () => void;
  loading?: boolean;
  title?: ReactNode;
  supTitle?: ReactNode;
  toolbarChildren?: ReactNode;
  sheetStyle?: CSSProperties;
  sheetClassName?: string;
}) {
  const modal = useModal();
  const top = modal.index === 0 ? "0.5" : modal.index * 1.5;
  const theme = useTheme();

  useEffect(function () {
    document.body.style.overflow = "hidden";
    return function () {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleClose = function () {
    return close ? close() : modal.remove();
  };

  const Header = function () {
    return (
      <Toolbar
        sx={{
          overflow: "hidden",
          position: "relative",
          justifyContent: "flex-end",
          flexShrink: 0,
          borderBottom: "1px solid",
          borderColor: "#e7e5f5",
          backgroundColor: "background.default",
          zIndex: 2,
          ...theme.applyStyles("dark", {
            borderColor: "#7e7e7e",
            backgroundColor: "#333",
          }),
        }}
      >
        <div style={{ flexGrow: 1 }}>
          {supTitle && (
            <Typography
              data-testid="navigation-sup-title"
              noWrap
              sx={supTitleStyle}
            >
              {supTitle}
            </Typography>
          )}
          <Typography
            display="block"
            sx={{
              flexGrow: 1,
              fontSize: 18,
              letterSpacing: "-0.01rem",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            {title}
          </Typography>
        </div>
        {toolbarChildren ? (
          <div style={{ paddingRight: "28px" }}>{toolbarChildren}</div>
        ) : null}
        <IconButton
          onClick={handleClose}
          aria-label="close"
          sx={{
            pl: 3,
            pr: 4,
            mr: -3,
            "&:hover": {
              backgroundColor: "transparent",
              "&:after": {
                content: "''",
                position: "absolute",
                top: 0,
                right: "8px",
                bottom: 0,
                left: 0,
                borderRadius: 2,
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            },
          }}
        >
          <CloseIcon />
        </IconButton>

        {loading && <LinearProgress color="secondary" sx={progressStyle} />}
      </Toolbar>
    );
  };
  return (
    <DrawerPortal>
      {modal.index === 0 && (
        <Backdrop
          open={true}
          onClick={modal.removeAll}
          sx={(theme) => ({
            zIndex: theme.zIndex.modal - 2,
          })}
        />
      )}
      <Sheet
        initial={{ x: "100%" }}
        animate={{ x: "0%", scale: modal.isBuried ? 0.96 : 1.0 }}
        transition={{ type: "spring", bounce: 0.2, duration: 0.35 }}
        className={`sheet MuiPaper-root ${sheetClassName}`}
        style={{
          top: top + "rem",
          filter: modal.isBuried
            ? "drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.175))"
            : "drop-shadow(0 -3px 4px rgba(0, 0, 0, 0.25))",
          transformOrigin: "top center",
          ...sheetStyle,
        }}
      >
        <Header />
        <div
          ref={bodyRef}
          className="MuiList-root MuiList-padding"
          style={{
            flexGrow: 1,
            overflowX: "hidden",
            overflowY: "auto",
            paddingBottom: 0,
          }}
        >
          {children}
        </div>
        <AnimatePresence>
          {modal.isBuried && (
            <DrawerCover
              initial={{ opacity: 0 }}
              animate={{
                opacity: 0.3,
              }}
              exit={{ opacity: 0 }}
              onClick={modal.removeAllAbove}
            />
          )}
        </AnimatePresence>
      </Sheet>
    </DrawerPortal>
  );
};
