import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import { EditTextNotepad } from "@/components/EditTextNotepad";

import type { TScholarship } from "@/models/scholarship/types";
import type { Entries } from "@/type-helpers";

const camelToSpaces = function (str) {
  return str.replace(/[a-z]/gi, (m, o) =>
    m < {} && o ? ` ${m}` : o ? m : m.toUpperCase(),
  );
};

const BLACKLIST_KEYS = [
  "_id",
  "author",
  "createdAt",
  "completedAt",
  "meta",
  "message",
  "notes",
  "partner",
  "time",
  "statement",
  "status",
] as const;

type BlacklistKey = (typeof BLACKLIST_KEYS)[number];
type FilteredScholarship = Omit<TScholarship, BlacklistKey>;

const getEntries = <T extends object>(obj: T) =>
  Object.entries(obj) as Entries<T>;

const filterScholarship = function (scholarship) {
  const _scholarship = { ...scholarship };
  BLACKLIST_KEYS.forEach(function (key) {
    delete _scholarship[key];
  });
  return _scholarship;
};

const HEADING_STYLEZ = {
  borderBottom: "1px solid #ccc",
  opacity: "0.7",
} as const;

const ScholarshipRequest = function ({
  scholarship,
  essay,
  isSaving,
  save,
}: {
  scholarship: TScholarship;
  essay: string;
  isSaving: boolean;
  save: (data: TScholarship) => void;
}) {
  const [statement, setStatement] = useState(scholarship.statement);

  // This forces the view to update when navigating between scholarships
  useEffect(() => {
    setStatement(scholarship.statement || null);
  }, [scholarship]);

  const handleSave = function () {
    const newData = { ...scholarship };

    if (statement !== scholarship.statement) {
      newData.statement = statement;
    }

    save(newData);
  };

  const handleStatementChange = (e) => {
    setStatement(e.target.value);
  };

  return (
    <>
      {scholarship && (
        <>
          <Grid container spacing={4}>
            {getEntries<FilteredScholarship>(
              filterScholarship(scholarship),
            ).map(function ([key, value]) {
              return (
                <Grid key={key} size={{ xs: 12, md: 6 }}>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    style={HEADING_STYLEZ}
                  >
                    {camelToSpaces(key)}
                  </Typography>
                  {value}
                </Grid>
              );
            })}
          </Grid>

          <br />
          <br />

          <EditTextNotepad
            color="purple"
            onChange={handleStatementChange}
            title="Scholarship Essay"
            value={statement}
          />

          <br />

          <div>
            <Button
              color="primary"
              loading={isSaving}
              onClick={handleSave}
              sx={{ minWidth: "6rem" }}
              variant="contained"
            >
              Save
            </Button>
          </div>

          <br />
          <br />

          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={HEADING_STYLEZ}
          >
            Application Essay
          </Typography>
          {essay ? essay : <em>No Essay Submitted</em>}

          <br />
        </>
      )}
    </>
  );
};

export default ScholarshipRequest;
