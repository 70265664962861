import Box from "@mui/material/Box";

import { Drawer, DrawerManager } from "@/components/Drawer";
import OrderDetails from "@/routes/Orders/components/OrderDetails";

import type { TOrder } from "@/models/order/types";

export const OrderDrawer = DrawerManager.create(function ({
  order,
}: {
  order: TOrder;
}) {
  return (
    <Drawer title="Order">
      {order ? (
        <Box sx={{ p: 2, bgcolor: "background.paper" }}>
          <OrderDetails orderData={order} />
        </Box>
      ) : null}
    </Drawer>
  );
});
