import type { TChatErrorMessage, TChatMessage } from "@/models/chat/types";

export const itemIsChatMessage = (json: any): json is TChatMessage => {
  return json?.conversationId;
};

export const itemIsChatErrorMessage = (
  json: any,
): json is TChatErrorMessage => {
  return json?.type === "error";
};

export const itemIsExpiredAuthErrorMessage = (
  json: any,
): json is TChatErrorMessage => {
  return json?.type === "error" && json?.error?.code === "AUTH_EXPIRED";
};
