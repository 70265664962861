import { Link } from "react-router";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import clientRoutes from "@/lib/client-routes";

function ProfileName({ _id, partnerID = "all" }) {
  const url = clientRoutes.profiles.show(partnerID, _id);

  const ListItemSX = {
    borderRadius: 2,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 2,
  };

  return (
    <ListItemButton
      component={Link}
      to={url}
      state={{ author: _id }}
      sx={ListItemSX}
    >
      <ListItemText
        slotProps={{
          primary: { color: "primary" },
        }}
        primary={_id}
      />
    </ListItemButton>
  );
}

export default ProfileName;
