import { Controller, useForm } from "react-hook-form";

import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import NativeSelect from "@mui/material/NativeSelect";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { ActionButtons } from "@/components/ActionButtons";
import FormErrorMessage from "@/components/FormErrorMessage";
import { AGES, FORM_PAPER_HEADING_SX, FORM_PAPER_SX } from "@/constants";
import { countries } from "@/lib/countries";
import { usStates } from "@/lib/us-states";
import Contact from "@/models/contact";

import type { TContact } from "@/models/contact/types";

export default function ContactForm({
  cancel,
  contactData,
  isError,
  isNewContact,
  isSaving,
  onSubmit,
}: {
  cancel: () => void;
  contactData: TContact;
  isError: boolean;
  isSaving: boolean;
  isNewContact: boolean;
  onSubmit: (data: TContact) => void;
}) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<TContact>({
    defaultValues: {
      ...Contact.propertiesOrDefaults(contactData),
    },
  });

  const age = watch("age");
  const underageConsent = watch("underageConsent");
  const country = watch("address.country");

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
      <Paper elevation={1} sx={FORM_PAPER_SX}>
        {isNewContact ? (
          <FormControl fullWidth margin="normal">
            <Controller
              name="email"
              control={control}
              rules={{ required: "This field is required" }}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <TextField
                    error={Boolean(errors.email)}
                    inputRef={ref}
                    label="Email"
                    required
                    size="small"
                    variant="outlined"
                    {...rest}
                  />
                );
              }}
            />
            <FormErrorMessage error={errors.email} />
          </FormControl>
        ) : null}

        <FormControl fullWidth margin="normal">
          <Controller
            name="firstName"
            control={control}
            rules={{ required: "This field is required" }}
            render={({ field: { ref, ...rest } }) => {
              return (
                <TextField
                  error={Boolean(errors.firstName)}
                  inputRef={ref}
                  label="First Name"
                  required
                  size="small"
                  variant="outlined"
                  {...rest}
                />
              );
            }}
          />
          <FormErrorMessage error={errors.firstName} />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <Controller
            name="lastName"
            control={control}
            rules={{ required: "This field is required" }}
            render={({ field: { ref, ...rest } }) => {
              return (
                <TextField
                  error={Boolean(errors.lastName)}
                  inputRef={ref}
                  label="Last Name"
                  required
                  size="small"
                  variant="outlined"
                  {...rest}
                />
              );
            }}
          />
          <FormErrorMessage error={errors.lastName} />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <Controller
            name="phone"
            control={control}
            render={({ field: { ref, ...rest } }) => {
              return (
                <TextField
                  error={Boolean(errors.phone)}
                  inputRef={ref}
                  label="Phone"
                  size="small"
                  variant="outlined"
                  {...rest}
                />
              );
            }}
          />
        </FormControl>
      </Paper>

      <Typography variant="h6" component="h3" sx={FORM_PAPER_HEADING_SX}>
        Address
      </Typography>

      <Paper elevation={1} sx={FORM_PAPER_SX}>
        <FormControl fullWidth margin="normal">
          <Controller
            name="address.street1"
            control={control}
            render={({ field: { ref, ...rest } }) => {
              return (
                <TextField
                  error={Boolean(errors.address?.street1)}
                  inputRef={ref}
                  label="Street 1"
                  size="small"
                  variant="outlined"
                  {...rest}
                />
              );
            }}
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <Controller
            name="address.street2"
            control={control}
            render={({ field: { ref, ...rest } }) => {
              return (
                <TextField
                  error={Boolean(errors.address?.street2)}
                  inputRef={ref}
                  label="Street 2"
                  size="small"
                  variant="outlined"
                  {...rest}
                />
              );
            }}
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <Controller
            name="address.city"
            control={control}
            render={({ field: { ref, ...rest } }) => {
              return (
                <TextField
                  error={Boolean(errors.address?.city)}
                  inputRef={ref}
                  label="City"
                  size="small"
                  variant="outlined"
                  {...rest}
                />
              );
            }}
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel shrink htmlFor="country-select">
            Country
          </InputLabel>
          <Controller
            control={control}
            name="address.country"
            render={({ field }) => (
              <NativeSelect
                inputProps={{
                  ...field,
                  id: "country-select",
                }}
                size="small"
              >
                <option value=""></option>
                {countries.map((c) => {
                  return (
                    <option key={c.name} value={c.name}>
                      {c.name}
                    </option>
                  );
                })}
              </NativeSelect>
            )}
          />
        </FormControl>

        {country === "United States" ? (
          <FormControl fullWidth margin="normal">
            <InputLabel shrink htmlFor="state-select">
              State
            </InputLabel>
            <Controller
              control={control}
              name="address.state"
              defaultValue=""
              render={({ field }) => (
                <NativeSelect
                  inputProps={{
                    ...field,
                    id: "state-select",
                  }}
                  size="small"
                >
                  <option value=""></option>
                  {usStates.map((c) => {
                    return (
                      <option key={c.name} value={c.name}>
                        {c.name}
                      </option>
                    );
                  })}
                </NativeSelect>
              )}
            />
          </FormControl>
        ) : (
          <FormControl fullWidth margin="normal">
            <Controller
              name="address.region"
              control={control}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <TextField
                    error={Boolean(errors.address?.region)}
                    inputRef={ref}
                    label="Region"
                    size="small"
                    variant="outlined"
                    {...rest}
                  />
                );
              }}
            />
          </FormControl>
        )}
        <FormControl fullWidth margin="normal">
          <Controller
            name="address.zipCode"
            control={control}
            render={({ field: { ref, ...rest } }) => {
              return (
                <TextField
                  error={Boolean(errors.address?.zipCode)}
                  inputRef={ref}
                  label="Zip Code"
                  size="small"
                  variant="outlined"
                  {...rest}
                />
              );
            }}
          />
        </FormControl>
      </Paper>

      <Typography variant="h6" component="h3" sx={FORM_PAPER_HEADING_SX}>
        Legal
      </Typography>

      <Paper elevation={1} sx={FORM_PAPER_SX}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="age-label">Age</InputLabel>
          <Controller
            control={control}
            name="age"
            render={({ field }) => (
              <Select {...field} labelId="age-label" size="small">
                <MenuItem placeholder="Make a selection" value="">
                  Make a selection
                </MenuItem>
                {AGES.map((age) => {
                  return (
                    <MenuItem key={age.value} value={age.value}>
                      {age.displayValue}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          />
        </FormControl>

        {age && parseInt(age) < 16 ? (
          <FormControl fullWidth>
            <Controller
              defaultValue={underageConsent || false}
              name="underageConsent"
              control={control}
              render={({ field: { onChange, value, name, ref } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value}
                      color="primary"
                      inputRef={ref}
                      name={name}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                  label="Underage Consent"
                  labelPlacement="end"
                />
              )}
            />
          </FormControl>
        ) : null}

        <FormControl fullWidth>
          <Controller
            name="crossPartnerMarketingOptIn"
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    color="primary"
                    inputRef={ref}
                    name={name}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
                label="Cross Partner Marketing Consent"
                labelPlacement="end"
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth>
          <Controller
            name="tcpaConsent"
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    color="primary"
                    inputRef={ref}
                    name={name}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
                label="Consent to be contacted by phone number"
                labelPlacement="end"
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth>
          <Controller
            name="touppAccept"
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    color="primary"
                    inputRef={ref}
                    name={name}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
                label="Kaplan Online Immersion TOU and PP Acceptance"
                labelPlacement="end"
              />
            )}
          />
        </FormControl>
      </Paper>

      <ActionButtons cancel={cancel} isSaving={isSaving}>
        {isError ? (
          <FormErrorMessage gutterBottom text="An error occurred" />
        ) : null}
      </ActionButtons>
    </form>
  );
}
