import { useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import Edit from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ChangeDates from "./ChangeDates";

export default function LineItem({ item, remove, add }) {
  const [confirm, setConfirm] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [datesOpen, setDatesOpen] = useState(false);

  const yesRemove = async () => {
    setIsRemoving(true);
    await remove({ inventoryItemID: item._id });
  };
  const swapDates = async (inventoryID) => {
    await add({ inventoryItemID: inventoryID, refetch: false });
    await remove({ inventoryItemID: item._id, wait: true });
  };
  return (
    <>
      <Box
        sx={{
          pt: 1.5,
          borderBottom: 1,
          borderColor: "divider",
          pb: 1.5,
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <IconButton
            aria-label="remove item"
            color="error"
            onClick={() => {
              setConfirm(!confirm);
            }}
          >
            <ClearIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1 }}>
            <Stack direction="row" alignItems="center" spacing={2} useFlexGap>
              <Typography variant="overline">
                {item.formattedStartDate} — {item.formattedEndDate}
              </Typography>
              <Button onClick={() => setDatesOpen(true)} startIcon={<Edit />}>
                Change Dates
              </Button>
            </Stack>

            <Typography
              gutterBottom
              variant="body1"
              fontWeight="bold"
              sx={{ mt: -0.25 }}
            >
              {item.catalogueItem.name}
            </Typography>

            <Box sx={{ position: "relative" }}>
              <Box
                sx={[
                  confirm
                    ? {
                        opacity: 0,
                      }
                    : {
                        opacity: 0.9,
                      },
                ]}
              >
                <Typography variant="body2">
                  Retail price: {item.formattedRetailPrice}
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  Price to pay: {item.formattedPriceToPay}
                </Typography>
              </Box>
              {confirm ? (
                <Box
                  sx={{ position: "absolute", top: 0, left: 0 }}
                  className="animation-fade-in"
                >
                  <Button
                    color="neutral"
                    disabled={isRemoving}
                    onClick={() => {
                      setConfirm(false);
                    }}
                    size="small"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="error"
                    loading={isRemoving}
                    onClick={yesRemove}
                    size="small"
                  >
                    Yes, remove
                  </Button>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
      <ChangeDates
        open={datesOpen}
        close={() => setDatesOpen(false)}
        inventoryItem={item}
        swapDates={swapDates}
      />
    </>
  );
}
