import Button from "@mui/material/Button";

import UPDialog from "@/components/UPDialog";

export default function ConfirmWithdraw({ handleClose, mutation, enrollment }) {
  return (
    <UPDialog
      close={handleClose}
      Title="Are you sure you want to withdraw this enrollment? You cannot undo
      this action."
      Actions={
        <>
          <Button color="secondary" onClick={handleClose} variant="outlined">
            Never mind
          </Button>
          <Button
            color="primary"
            loading={mutation.isPending}
            onClick={() => {
              mutation.mutate(enrollment);
            }}
            variant="contained"
          >
            Withdraw enrollment
          </Button>
        </>
      }
    />
  );
}
