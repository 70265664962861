import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router";

import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import { Accordion } from "@/components/Accordion";
import { Layout } from "@/components/Layout";
import { TitleBar } from "@/components/TitleBar";
import { TitleBarButton } from "@/components/TitleBarButton";
import Contact from "@/models/contact";

import { CouponsView } from "./components/CouponView";
import { EnrollmentsView } from "./components/EnrollmentsView";
import { ForgetDialog } from "./components/ForgetDialog";
import { Forgotten } from "./components/Forgotten";
import { OrdersView } from "./components/OrdersView";
import { Profile } from "./components/Profile";
import { WIPBanner } from "./components/WIPBanner";
import styles from "./show.module.css";

export default function ContactShow() {
  const { _id } = useParams();
  const [showForgetDialog, setShowForgetDialog] = useState(false);
  const contact = useQuery(Contact.findById.queryOptions(_id));

  const couponIds = (
    contact.data?.profiles?.map((p) => p.coupons ?? []) ?? []
  ).flat();

  if (contact.isLoading) {
    return (
      <Layout>
        <TitleBar
          title="Contacts"
          backFunction={() => {
            window.history.back();
          }}
        />

        <Layout.Content>
          <LinearProgress
            color="secondary"
            sx={{ position: "absolute", top: 0, right: 0, left: 0 }}
          />
        </Layout.Content>
      </Layout>
    );
  }

  if (contact.isError) {
    return (
      <Layout>
        <TitleBar
          title="Contacts"
          backFunction={() => {
            window.history.back();
          }}
        />
        <Layout.Content>
          <Box sx={{ mb: 2 }}>Error: {contact.error.message}</Box>
        </Layout.Content>
      </Layout>
    );
  }

  if (contact.data) {
    if (contact.data?.forgotten) {
      return (
        <Layout>
          <TitleBar
            title="Contacts"
            backFunction={() => {
              window.history.back();
            }}
          />
          <Layout.Content>
            <Forgotten />
          </Layout.Content>
        </Layout>
      );
    }

    return (
      <Layout>
        <TitleBar
          title="Contacts"
          backFunction={() => {
            window.history.back();
          }}
        >
          <WIPBanner />
          <TitleBarButton
            onClick={() => {
              setShowForgetDialog(true);
            }}
            startIcon={<EmojiPeopleIcon />}
          >
            Forget
          </TitleBarButton>
        </TitleBar>
        {showForgetDialog ? (
          <ForgetDialog
            close={() => {
              setShowForgetDialog(false);
            }}
            id={contact.data._id}
          />
        ) : null}
        <Layout.Content>
          <br />
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              gap: "44px",
              flexDirection: "column",
            }}
          >
            {contact.data.profiles.map((p) => {
              return (
                <Profile
                  key={`${p.uuid}-${p.partner._id}`}
                  contact={contact.data}
                  profile={p}
                />
              );
            })}

            <div
              style={{
                display: "flex",
                flexGrow: 1,
                gap: "44px",
                flexDirection: "column",
              }}
            >
              <div>
                <Accordion.Root>
                  <Accordion.Item className={styles.AccordionItem}>
                    <Accordion.Header className={styles.AccordionHeader}>
                      <Accordion.Trigger className={styles.AccordionTrigger}>
                        <span className={styles.AccordionTriggerText}>
                          Orders{" "}
                          <span className={styles.AccordionTriggerCount}>
                            {contact.data.orders?.length || 0}
                          </span>
                        </span>
                      </Accordion.Trigger>
                    </Accordion.Header>
                    <Accordion.Panel>
                      <div className={styles.AccordionContent}>
                        <OrdersView orders={contact.data.orders ?? []} />
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion.Root>
              </div>

              <div>
                <Accordion.Root>
                  <Accordion.Item className={styles.AccordionItem}>
                    <Accordion.Header className={styles.AccordionHeader}>
                      <Accordion.Trigger className={styles.AccordionTrigger}>
                        <span className={styles.AccordionTriggerText}>
                          Coupons{" "}
                          <span className={styles.AccordionTriggerCount}>
                            {couponIds.length || 0}
                          </span>
                        </span>
                      </Accordion.Trigger>
                    </Accordion.Header>
                    <Accordion.Panel>
                      <div className={styles.AccordionContent}>
                        <CouponsView contactIds={couponIds} />
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion.Root>
              </div>

              <div>
                <Accordion.Root>
                  <Accordion.Item className={styles.AccordionItem}>
                    <Accordion.Header className={styles.AccordionHeader}>
                      <Accordion.Trigger className={styles.AccordionTrigger}>
                        <span className={styles.AccordionTriggerText}>
                          Enrollments{" "}
                          <span className={styles.AccordionTriggerCount}>
                            {contact.data.enrollments?.length || 0}
                          </span>
                        </span>
                      </Accordion.Trigger>
                    </Accordion.Header>
                    <Accordion.Panel>
                      <div className={styles.AccordionContent}>
                        {contact.data.enrollments?.map((e) => {
                          return (
                            <EnrollmentsView
                              key={e._id}
                              partnerId={e.partner._id}
                              email={e.student.email}
                            />
                          );
                        })}
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion.Root>
              </div>

              <div>
                <Accordion.Root>
                  <Accordion.Item className={styles.AccordionItem}>
                    <Accordion.Header className={styles.AccordionHeader}>
                      <Accordion.Trigger className={styles.AccordionTrigger}>
                        <span className={styles.AccordionTriggerText}>
                          Relationships
                        </span>
                      </Accordion.Trigger>
                    </Accordion.Header>
                    <Accordion.Panel>
                      <div className={styles.AccordionContent}>
                        Coming soon...
                      </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion.Root>
              </div>

              <br />
            </div>
          </div>
        </Layout.Content>
      </Layout>
    );
  } else {
    return <div>no contact found</div>;
  }
}
