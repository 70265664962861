import { TextField } from "@mui/material";

export default function ShowScholarshipId({ scholarshipId }) {
  return (
    <TextField
      label="Associated Scholarship:"
      defaultValue={scholarshipId}
      variant="filled"
      fullWidth
      name="scholarshipId"
      slotProps={{
        input: {
          readOnly: true,
        },
      }}
    />
  );
}
