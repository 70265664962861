import { Accordion } from "@base-ui-components/react";
import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { useNavigate } from "react-router";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { CopyToClipboardButton } from "@/components/CopyToClipboardButton";
import { Data } from "@/components/Data";
import { useDrawer } from "@/components/Drawer";
import { ApplicationDrawer } from "@/components/Drawers/ApplicationDrawer";
import { CartDrawer } from "@/components/Drawers/CartDrawer";
import ContactFormDrawer from "@/components/Drawers/ContactFormDrawer";
import ScholarshipDrawer from "@/components/Drawers/ScholarshipDrawer";
import { TitleBarButton } from "@/components/TitleBarButton";
import { UPChip } from "@/components/UPChip";
import { JourneyStage, PARTNER_STYLES } from "@/constants";
import { useAlert } from "@/hooks/use-alert";
import clientRoutes from "@/lib/client-routes";

import styles from "./index.module.css";

import type { TContact } from "@/models/contact/types";
import type { TProfile } from "@/models/profile/types";

export const Profile = function Profile({
  contact,
  profile,
}: {
  contact: TContact;
  profile: TProfile;
}) {
  const p = profile;
  const showAlert = useAlert();
  const queryClient = useQueryClient();
  const navigateTo = useNavigate();
  const contactFormDrawer = useDrawer(ContactFormDrawer);
  const cartDrawer = useDrawer(CartDrawer);
  const applicationDrawer = useDrawer(ApplicationDrawer);
  const scholarshipDrawer = useDrawer(ScholarshipDrawer);

  const application = contact.applications?.find(
    (a) => profile.partner._id === a.partner._id,
  );

  const scholarship = contact.scholarships?.find(
    (s) => profile.partner._id === s.partner._id,
  );

  const showApplication = function (email: string, partnerId: string) {
    applicationDrawer.show({
      cancelApplicationSuccessCallback() {
        applicationDrawer.remove();
        // TODO: refetch contact
      },
      email,
      partnerId,
      successCallback() {
        showAlert({
          message: `Application updated`,
        });
        applicationDrawer.remove();
      },
    });
  };

  const showScholarship = function (email: string, partnerId: string) {
    scholarshipDrawer.show({
      email,
      partnerId,
      successCallback() {
        scholarshipDrawer.remove();
      },
    });
  };

  return (
    <div>
      <Accordion.Root defaultValue={[0]}>
        <Accordion.Item className={styles.profileRoot}>
          <Accordion.Header
            render={(props) => {
              return (
                <div {...props}>
                  <Accordion.Trigger className={styles.accordionTrigger}>
                    <ArrowForwardIosIcon
                      style={{
                        color: "currentColor",
                        fontSize: "13px",
                      }}
                    />
                    <div aria-hidden className={styles.accordionTriggerBg} />
                  </Accordion.Trigger>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={1}
                    sx={{ position: "relative", overflow: "hidden" }}
                  >
                    <div style={{ display: "flex", flexGrow: 1, gap: "20px" }}>
                      <img
                        src={
                          PARTNER_STYLES.find((s) => s._id === p.partner._id)
                            ?.logo
                        }
                        alt={p.partner.name}
                        style={{
                          width: "44px",
                          height: "44px",
                          borderRadius: "50%",
                        }}
                      />
                      <div style={{ marginBottom: "16px" }}>
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "14px", lineHeight: 1, opacity: 0.7 }}
                        >
                          {p.partner.name}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "28px", lineHeight: 1.2 }}
                        >
                          {p.firstName ? (
                            <strong>{p.firstName}</strong>
                          ) : (
                            <em>first name</em>
                          )}{" "}
                          {p.lastName ? (
                            <strong>{p.lastName}</strong>
                          ) : (
                            <em>last name</em>
                          )}
                          {p.type ? (
                            <span className={styles.typeBadge}>{p.type}</span>
                          ) : null}
                        </Typography>
                      </div>
                    </div>

                    <TitleBarButton
                      onClick={() => {
                        cartDrawer.show({
                          profile,
                          cancel() {
                            cartDrawer.remove();
                          },
                        });
                      }}
                      startIcon={<ShoppingCartIcon />}
                    >
                      Live Cart
                    </TitleBarButton>
                    <TitleBarButton
                      disabled
                      onClick={() => {}}
                      startIcon={<AddCircleIcon />}
                      sx={{
                        opacity: 0.5,
                      }}
                    >
                      New Coupon
                    </TitleBarButton>
                    <TitleBarButton
                      startIcon={<ViewTimelineIcon />}
                      disabled
                      sx={{
                        opacity: 0.5,
                      }}
                    >
                      Timeline
                    </TitleBarButton>
                    <TitleBarButton
                      disabled
                      onClick={() => {
                        contactFormDrawer.show({
                          cancel() {
                            contactFormDrawer.remove();
                          },
                          data: contact,
                          successCallback(data: Partial<TContact>) {
                            showAlert({ message: "Contact updated!" });
                            queryClient.invalidateQueries({
                              queryKey: ["contact", contact._id],
                            });

                            contactFormDrawer.remove();
                            navigateTo(clientRoutes.contacts.show(data._id));
                          },
                        });
                      }}
                      startIcon={<EditIcon />}
                      sx={{
                        opacity: 0.5,
                      }}
                    >
                      Edit
                    </TitleBarButton>
                  </Stack>
                </div>
              );
            }}
          />
          <Accordion.Panel>
            <div style={{ display: "flex", flexGrow: 1, gap: "20px" }}>
              <div style={{ width: "44px" }} aria-hidden />
              <div>
                <Data condensed>
                  <Data.Item>
                    <Data.Label>Email</Data.Label>
                    <Data.Content>{p.email}</Data.Content>
                    <CopyToClipboardButton
                      text={p.email}
                      style={{
                        fontSize: "16px",
                        padding: "2px 4px",
                      }}
                    />
                  </Data.Item>
                  {p.lastLoggedIn ? (
                    <Data.Item>
                      <Data.Label>Last Sign In</Data.Label>
                      <Data.Content>
                        {format(p.lastLoggedIn, "MM/dd/yyyy - hh:mmaaa")}
                      </Data.Content>
                    </Data.Item>
                  ) : null}
                  {p.age ? (
                    <Data.Item>
                      <Data.Label>Age</Data.Label>
                      <Data.Content>{p.age}</Data.Content>
                    </Data.Item>
                  ) : null}
                  {p.phone ? (
                    <Data.Item>
                      <Data.Label>Phone</Data.Label>
                      <Data.Content>{p.phone}</Data.Content>
                    </Data.Item>
                  ) : null}
                </Data>
                {p.address ? (
                  <Data condensed style={{ display: "flex", gap: "12px" }}>
                    <Data.Item>
                      <Data.Label>Country</Data.Label>
                      <Data.Content>{p.address.country ?? "—"}</Data.Content>
                    </Data.Item>
                    <Data.Item>
                      <Data.Label>Zip Code</Data.Label>
                      <Data.Content>{p.address.zipCode ?? "—"}</Data.Content>
                    </Data.Item>
                  </Data>
                ) : null}
              </div>
              <div>
                <Typography
                  variant="overline"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minHeight: "28px",
                    gap: "0.25rem",
                    lineHeight: "1",
                    opacity: 0.7,
                  }}
                >
                  Legal
                </Typography>
                <Data condensed>
                  <Data.Item>
                    <Data.Label>tcpaConsent</Data.Label>
                    <Data.Content>{p.tcpaConsent ? "yes" : "no"}</Data.Content>
                  </Data.Item>
                  <Data.Item>
                    <Data.Label>underageConsent (jerry fix)</Data.Label>
                    <Data.Content>
                      {p.underageConsent ? "yes" : "no"}
                    </Data.Content>
                  </Data.Item>
                  <Data.Item>
                    <Data.Label>crossPartnerMarketingOptIn</Data.Label>
                    <Data.Content>
                      {p.crossPartnerMarketingOptIn ? "yes" : "no"}
                    </Data.Content>
                  </Data.Item>
                  <Data.Item>
                    <Data.Label>touppAccept</Data.Label>
                    <Data.Content>{p.touppAccept ? "yes" : "no"}</Data.Content>
                  </Data.Item>
                </Data>
              </div>
            </div>

            <br />

            <div style={{ display: "flex", flexGrow: 1, gap: "20px" }}>
              <div style={{ width: "44px" }} aria-hidden />
              <div className={styles.timelineRoot}>
                <div className={styles.applicationLabel}>
                  <div className={styles.timelineLabelDivider} aria-hidden />
                  <button
                    className={styles.labelButton}
                    onClick={() => {
                      showApplication(p.email, p.partner._id);
                    }}
                  >
                    Application
                    <VisibilityIcon sx={{ fontSize: "inherit" }} />
                  </button>
                </div>
                <div
                  style={{ gridColumn: "5 / 8" }}
                  aria-hidden
                  data-el="spacer"
                />

                {Object.entries(JourneyStage).map(([key, value]) => {
                  return (
                    <div key={key} className={styles.timelineItem}>
                      <UPChip
                        color={
                          value === JourneyStage.Approved ||
                          value === JourneyStage.Enrolled
                            ? "green"
                            : "off"
                        }
                        label={value}
                        sx={{ width: "100%" }}
                      />
                      <div className={styles.timelineItemSubText}>
                        Jan 20, 2025
                      </div>
                    </div>
                  );
                })}

                <div
                  style={{ gridColumn: "1 / 8", height: "4px" }}
                  aria-hidden
                  data-el="spacer"
                />

                <div className={styles.scholarshipLabel}>
                  <div className={styles.timelineLabelDivider} aria-hidden />
                  {scholarship ? (
                    <button
                      className={styles.labelButton}
                      onClick={() => {
                        showScholarship(p.email, p.partner._id);
                      }}
                    >
                      Scholarship
                      <VisibilityIcon sx={{ fontSize: "inherit" }} />
                    </button>
                  ) : (
                    <span className={styles.label}>Scholarship</span>
                  )}
                </div>
                <div
                  style={{ gridColumn: "5 / 8" }}
                  aria-hidden
                  data-el="spacer"
                />

                <div
                  style={{ gridColumn: "1 / 3" }}
                  aria-hidden
                  data-el="spacer"
                />
                <div className={styles.timelineItem}>
                  <UPChip color="off" label="Pending" sx={{ width: "100%" }} />
                  <div className={styles.timelineItemSubText}>Jan 20, 2025</div>
                </div>
                <div className={styles.timelineItem}>
                  <UPChip color="off" label="Approved" sx={{ width: "100%" }} />
                  <div className={styles.timelineItemSubText}>
                    Jan 20, 2025
                    <br /> 50%
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion.Root>
    </div>
  );
};
