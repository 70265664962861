import { useState } from "react";

import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";

import styles from "./index.module.css";

export default function SearchForm({ onSubmit, query = "" }) {
  const handleSubmit = function (event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    onSubmit(data.get("query"));
  };

  const [showHelp, setShowHelp] = useState(false);

  return (
    <form onSubmit={handleSubmit}>
      <Dialog
        open={showHelp}
        onClose={() => {
          setShowHelp(false);
        }}
        aria-describedby="help-description"
      >
        <DialogTitle>Search Help</DialogTitle>
        <DialogContent>
          <DialogContentText id="help-description">
            <Typography variant="body1" marginBottom={2}>
              <span style={{ fontWeight: "bold" }}>Search for an email</span>
              <code className={styles.fakeInput}>
                caroline.fairhurst@kaplan.com
              </code>
            </Typography>
            <Typography variant="body1" marginBottom={2}>
              <span style={{ fontWeight: "bold" }}>Search by phone</span>
              <code className={styles.fakeInput}>000000000</code>
            </Typography>
            <Typography variant="body1" marginBottom={2}>
              <span style={{ fontWeight: "bold" }}>Exact Term</span>
              <br />
              Use quotes to search for an exact term. For example, an exact
              email:
              <code className={styles.fakeInput}>
                &#34;caroline.fairhurst+1@kaplan.com&#34;
              </code>
            </Typography>
            <Typography variant="body1" marginBottom={2}>
              <span style={{ fontWeight: "bold" }}>
                Search for an exact name by first and last name:
              </span>
              <code className={styles.fakeInput}>
                &#34;Caroline&#34; &#34;Fairhurst&#34;
              </code>
            </Typography>
            <Typography variant="body1" marginBottom={2}>
              <span style={{ fontWeight: "bold" }}>Wildcards</span>
              <br />
              to see all Kaplan emails:
              <code className={styles.fakeInput}>*@kaplan.com</code>
            </Typography>
            <Typography variant="body1" marginBottom={2}>
              <span style={{ fontWeight: "bold" }}>
                Use OR and AND to search for multiple terms:
              </span>
              <code className={styles.fakeInput}>
                Fairhurst <code>OR</code> Gersing
              </code>
              <code className={styles.fakeInput}>
                Fairhurst AND &#34;Georgetown University&#34;
              </code>
            </Typography>
            <Typography variant="body1" marginBottom={2}>
              <span style={{ fontWeight: "bold" }}>Combine them</span>
              <code className={styles.fakeInput}>
                (fairhurst OR gersing) AND &#34;Georgetown University&#34;
              </code>
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Box
        sx={{ display: { sm: "flex" }, p: 2, gap: 1, alignItems: "flex-start" }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <FormControl variant="outlined" fullWidth={true}>
            <InputLabel htmlFor="search-query">Search</InputLabel>
            <OutlinedInput
              defaultValue={query}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="help"
                    onClick={() => {
                      setShowHelp(!showHelp);
                    }}
                    edge="end"
                  >
                    <HelpIcon />
                  </IconButton>
                </InputAdornment>
              }
              id="search-query"
              label="Search"
              name="query"
              size="small"
              type="text"
            />
          </FormControl>
        </Box>

        <Button
          color="neutral"
          type="submit"
          variant="outlined"
          sx={{ flexShrink: 0 }}
        >
          Search
        </Button>
      </Box>
    </form>
  );
}
