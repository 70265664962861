import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import FormErrorMessage from "@/components/FormErrorMessage";
import Mentor from "@/models/mentor";

import type { TMentorQualifiedCatalogueItem } from "@/models/mentor/types";
import type { MouseEvent } from "react";

export const CatalogueItemShow = function CatalogueItemShow(props: {
  catalogueItem: TMentorQualifiedCatalogueItem;
  email: string;
  remove: () => void;
}) {
  const deleteMutation = useMutation({
    mutationFn() {
      setDidError(false);
      return Mentor.deleteQualifiedCatalogueItems(
        props.email,
        props.catalogueItem,
      );
    },
    onSuccess: () => {
      props.remove();
    },
    onError() {
      setDidError(true);
    },
  });

  const [didError, setDidError] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {props.catalogueItem.name}
          </Typography>
          <Typography variant="body2">
            {props.catalogueItem.partner.name}
          </Typography>
        </Box>

        <Box sx={{ flexShrink: 0, pt: 0.75 }}>
          <IconButton
            color="error"
            disabled={showConfirmDelete}
            size="small"
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              setShowConfirmDelete(true);
            }}
          >
            <CancelIcon />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        {didError ? <FormErrorMessage text="An error occurred" /> : null}
        {showConfirmDelete ? (
          <Box
            className="animation-fade-in"
            sx={{
              display: "flex",
              gap: 1,
              pt: 1,
              justifyContent: "end",
              flexGrow: 1,
            }}
          >
            <Button
              size="small"
              loading={deleteMutation.isPending}
              variant="contained"
              color="error"
              onClick={() => {
                deleteMutation.mutate();
              }}
            >
              Yes, Remove
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                setShowConfirmDelete(false);
              }}
              sx={{ minWidth: "6rem" }}
            >
              Cancel
            </Button>
          </Box>
        ) : null}
      </Box>
    </div>
  );
};
