import { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

export default function AddCoupon({ isSaving, save }) {
  const [code, setCode] = useState("");

  return (
    <Box
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
        save(code).then(() => {
          setCode("");
        });
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <TextField
        defaultValue={code}
        disabled={isSaving}
        label="Code"
        name="email"
        onChange={(e) => {
          setCode(e.target.value);
        }}
        variant="outlined"
      />
      <Button
        color="secondary"
        disabled={!code}
        loading={isSaving}
        size="small"
        type="submit"
        variant="outlined"
      >
        Apply
      </Button>
    </Box>
  );
}
