import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import Button from "@mui/material/Button";

import FormErrorMessage from "@/components/FormErrorMessage";
import UPDialog from "@/components/UPDialog";
import { useAlert } from "@/hooks/use-alert";
import Contact from "@/models/contact";

export const ForgetDialog = function ForgetDialog({ close, id }) {
  const queryClient = useQueryClient();
  const showAlert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirm, setConfirm] = useState(false);

  return (
    <UPDialog
      close={close}
      Icon={EmojiPeopleIcon}
      Title="Are you sure you want to permanently forget this contact?"
      Content="You cannot undo this action."
      Actions={
        <>
          <Button
            color="neutral"
            loading={isLoading}
            onClick={close}
            variant="outlined"
            sx={{ minWidth: 140 }}
          >
            Never mind
          </Button>
          <Button
            loading={isLoading}
            onClick={() => {
              setConfirm(true);
            }}
            variant="contained"
          >
            Yes, Next Step
          </Button>
          {confirm ? (
            <div style={{ paddingTop: "12px", width: "100%" }}>
              <Button
                fullWidth
                disabled
                color="error"
                loading={isLoading}
                onClick={async () => {
                  try {
                    setIsLoading(true);
                    await Contact.forgetByID(id);
                    setIsLoading(false);
                    queryClient.invalidateQueries({
                      queryKey: ["contact", id],
                    });
                    showAlert({ message: "Contact forgotten" });
                    close();
                  } catch (err) {
                    setIsLoading(false);
                    setErrorMessage(err.message);
                  }
                }}
                variant="contained"
              >
                <span style={{ textDecoration: "line-through" }}>
                  Yes, Forget Contact Permanently
                </span>
              </Button>
            </div>
          ) : null}
          {errorMessage ? (
            <FormErrorMessage
              text={errorMessage}
              sx={{ flexBasis: "100%", mt: 1, justifyContent: "center" }}
            />
          ) : null}
        </>
      }
    />
  );
};
