import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router";

import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";

import { CopyToClipboardButton } from "@/components/CopyToClipboardButton";
import { Data } from "@/components/Data";
import { useDrawer } from "@/components/Drawer";
import InventoryCancelDrawer from "@/components/Drawers/InventoryCancelDrawer";
import { UPChip } from "@/components/UPChip";
import { useAlert } from "@/hooks/use-alert";
import { formatDate } from "@/lib/helpers";
import { useCatalogueItem } from "@/models/catalogue/hooks";
import Enrollment from "@/models/enrollment";
import Inventory from "@/models/inventory";
import { useInventoryItem } from "@/models/inventory/hooks";
import { usePartner } from "@/models/partner/hooks";

import ButtonStack from "./components/ButtonStack";
import ConfirmationDialog from "./components/ConfirmationDialog";
import DataStripe from "./components/DataStripe";
import DetailsHeader from "./components/DetailsHeader";
import EnrollmentsTable from "./components/EnrollmentsTable";
import { DataListLoading } from "./components/Loading";
import StatusBoard from "./components/StatusBoard";

const decorateEnrollments = function (enrollments) {
  return enrollments.map((e) => {
    return {
      ...e,
      studentName: `${e.student?.firstName} ${e.student?.lastName}`,
      location: e.student?.address?.country,
      orderId: e.order?._id,
    };
  });
};

const InventorySearchShow = function InventorySearchShow({ handleEdit }) {
  const theme = useTheme();
  const { _id } = useParams();
  const showAlert = useAlert();
  const queryClient = useQueryClient();
  const inventoryCancelDrawer = useDrawer(InventoryCancelDrawer);
  const [openDialog, setOpenDialog] = useState(false);

  // Data Fetching -------------------------------------------------------------
  const inventoryItem = useInventoryItem(_id);

  const catalogueItem = useCatalogueItem(
    inventoryItem.data?.catalogueItem._id,
    {
      enabled: Boolean(inventoryItem.data),
    },
  );

  const partner = usePartner(inventoryItem.data?.partner?._id, {
    enabled: Boolean(inventoryItem.data),
  });

  const enrollmentData = useQuery({
    queryKey: Enrollment.findByCohort.queryKey(_id),
    queryFn() {
      return Enrollment.findByCohort.queryFn(_id);
    },
  });

  const courseSections = useQuery({
    queryKey: Enrollment.fetchLmsSections.queryKey({
      partnerId: inventoryItem.data?.partner?._id,
      inventoryId: _id,
    }),
    queryFn() {
      return Enrollment.fetchLmsSections.queryFn({
        partnerId: inventoryItem.data?.partner?._id,
        inventoryId: _id,
      });
    },
    enabled: Boolean(inventoryItem.data),
  });

  // Event Handlers ------------------------------------------------------------

  const deleteMutation = useMutation({
    mutationFn(data) {
      return Inventory.deleteItem(_id, data?.newInventoryItem);
    },
    onSuccess() {
      inventoryCancelDrawer.remove();
      setOpenDialog(false);
      showAlert({
        message: `Successfully deleted inventory item`,
        duration: null,
      });
      queryClient.invalidateQueries({
        queryKey: ["inventory"],
      });
    },
    onError(error) {
      console.log(error);
      showAlert({ message: "An error has occurred", severity: "error" });
    },
  });

  const promoteMutation = useMutation({
    mutationFn() {
      return Inventory.promoteToMarket(inventoryItem.data);
    },
    onSuccess() {
      setOpenDialog(false);
      showAlert({
        message: `Successfully promoted inventory item`,
        duration: null,
      });
      queryClient.invalidateQueries({
        queryKey: ["inventory"],
      });
    },
    onError(error) {
      console.log(error);
      showAlert({ message: "An error has occurred", severity: "error" });
    },
  });

  const migrateMutation = useMutation({
    mutationFn() {
      return Inventory.migrateToCanvas(inventoryItem.data);
    },
    onSuccess() {
      setOpenDialog(false);
      queryClient.invalidateQueries({ queryKey: ["courseJob"] });
      showAlert({
        message: `Course queued for creation in Canvas`,
        duration: null,
      });
    },
    onError(error) {
      console.log(error);
      showAlert({ message: "An error has occurred", severity: "error" });
    },
  });

  const handleCancel = () => {
    if (enrollmentData.data?.enrollments?.length) {
      inventoryCancelDrawer.show({
        cancel() {
          inventoryCancelDrawer.remove();
        },
        inventoryItem: inventoryItem.data,
        isSaving: deleteMutation.isPending,
        onSubmit(data) {
          deleteMutation.mutate(data);
        },
      });
    } else if (enrollmentData.data?.enrollments?.length === 0) {
      setOpenDialog("cancel");
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const canvasUrl =
    partner.data &&
    `${partner.data?.linkLMS}/courses/sis_integration_id:${_id}`;

  return (
    <Box sx={{ pt: 3 }}>
      <Box>
        <DetailsHeader inventoryItem={inventoryItem} />

        {inventoryItem.data && (
          <ButtonStack
            inventoryItem={inventoryItem}
            handleCancel={handleCancel}
            setOpenDialog={setOpenDialog}
            handleEdit={handleEdit}
            courseSections={courseSections}
          />
        )}
      </Box>

      <ConfirmationDialog
        openDialog={openDialog}
        handleClose={handleClose}
        mutation={
          openDialog === "cancel"
            ? deleteMutation
            : openDialog === "promote"
              ? promoteMutation
              : migrateMutation
        }
      />

      <Box sx={{ height: "40px" }} />

      {inventoryItem.isLoading ? <DataListLoading /> : null}

      {inventoryItem.data ? (
        <Box className="data-stripe-wrapper" sx={{ mb: 4 }}>
          <DataStripe
            color="#73B991"
            label="MSRP"
            content={`$${catalogueItem.data?.msrp}`}
          />
          <DataStripe
            color="#73B991"
            label="Retail Price"
            content={`$${inventoryItem.data.retailPrice}`}
          />
          <DataStripe
            color="#EDBCB0"
            label="Created"
            content={formatDate(inventoryItem.data.createdAt)}
          />
          {enrollmentData.data ? (
            <DataStripe
              color="#548DEF"
              label="Seats Filled"
              content={`${enrollmentData.data?.enrollments?.length} / ${inventoryItem.data.maxCapacity}`}
            />
          ) : null}
        </Box>
      ) : null}

      {inventoryItem.data && (
        <>
          <Data>
            <Data.Item>
              <Data.Label>Canvas Onboarding:</Data.Label>
              <Data.Content>
                {inventoryItem.data.lmsEnrollmentRequested ? (
                  <UPChip
                    color="green"
                    label="Completed"
                    size="small"
                    icon={<CheckIcon />}
                  />
                ) : (
                  <UPChip label="Not started" size="small" />
                )}
              </Data.Content>
            </Data.Item>
            <Data.Item>
              <Data.Label>ID:</Data.Label>
              <CopyToClipboardButton text={inventoryItem.data._id} />
              <Data.Content>
                <code>{inventoryItem.data._id}</code>
              </Data.Content>
            </Data.Item>
            <Data.Item>
              <Data.Label sx={{ flexShrink: 0 }}>Express Link:</Data.Label>
              {catalogueItem.data ? (
                <>
                  {partner.data && (
                    <CopyToClipboardButton
                      text={`${partner.data.domain}/express/${inventoryItem.data._id}`}
                    />
                  )}
                  <Data.Content>
                    {partner.isLoading && <div>Loading...</div>}
                    {partner.data &&
                      `${partner.data.domain}/express/${inventoryItem.data._id}`}
                  </Data.Content>
                </>
              ) : null}
            </Data.Item>
            {courseSections.isSuccess ? (
              <Data.Item>
                <Data.Label>Canvas Course Link:</Data.Label>
                {partner.data ? (
                  <CopyToClipboardButton text={canvasUrl} />
                ) : null}
                <Data.Content>
                  {partner.data ? (
                    <a
                      href={canvasUrl}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: theme.palette.text.primary,
                      }}
                    >
                      {canvasUrl}
                    </a>
                  ) : (
                    "Loading..."
                  )}
                </Data.Content>
              </Data.Item>
            ) : null}
            {inventoryItem.data.withdrawDeadline ? (
              <Data.Item>
                <Data.Label>Withdrawal Deadline:</Data.Label>
                <Data.Content>
                  {formatDate(inventoryItem.data.withdrawDeadline)}
                </Data.Content>
              </Data.Item>
            ) : null}
          </Data>

          <div
            style={{
              background: theme.palette.mode === "light" ? "#f6f6f6" : "#222",
              paddingTop: "20px",
              marginTop: "2rem",
              paddingBottom: "20px",
              paddingLeft: "12px",
              paddingRight: "12px",
              borderRadius: "8px",
              boxShadow: "inset 0px 1px 2px 0 rgba(0,0,0, 0.15)",
            }}
          >
            <StatusBoard
              status={inventoryItem.data.status}
              enrollment={inventoryItem.data.availableForEnrollment}
              purchase={inventoryItem.data.availableForPurchase}
              appDeadline={inventoryItem.data.applicationDeadline}
              enrollDeadline={inventoryItem.data.enrollmentDeadline}
            />
          </div>
        </>
      )}
      <br />
      <br />
      {enrollmentData.isFetching && !enrollmentData.data ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CircularProgress size="1.25rem" sx={{ mr: 1.5 }} /> Loading
          enrollments...
        </Box>
      ) : null}
      {enrollmentData.data?.enrollments && catalogueItem.data?.partner && (
        <EnrollmentsTable
          captionTitle={`${enrollmentData.data.enrollments.length} Enrollment${
            enrollmentData.data.enrollments?.length === 1 ? "" : "s"
          }`}
          enrollments={decorateEnrollments(enrollmentData.data.enrollments)}
          inventoryId={_id}
          partnerId={catalogueItem.data.partner._id}
          courseSections={courseSections}
        />
      )}
      <br />
    </Box>
  );
};

export default InventorySearchShow;
