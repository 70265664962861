import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, Typography } from "@mui/material";

import { CopyToClipboardButton } from "@/components/CopyToClipboardButton";
import { Data } from "@/components/Data";
import { useDrawer } from "@/components/Drawer";
import { OrderDrawer } from "@/components/Drawers/OrderDrawer";
import { PARTNER_STYLES } from "@/constants";
import { formatDate } from "@/lib/helpers";

import styles from "../show.module.css";

import type { TContact } from "@/models/contact/types";

export const OrdersView = function ({
  orders,
}: {
  orders: TContact["orders"];
}) {
  const orderDrawer = useDrawer(OrderDrawer);

  return orders?.length ? (
    orders.map((order, i) => {
      return (
        <div key={`${order._id}-${i}`} className={styles.InfoCardRoot}>
          <img
            className={styles.InfoCardImage}
            src={PARTNER_STYLES.find((s) => s._id === order.partner._id)?.logo}
            alt={order.partner.name}
          />
          <div className={styles.InfoCardContent}>
            <div className={styles.InfoCardHeader}>
              <div style={{ flexGrow: 1 }}>
                <Typography
                  variant="body2"
                  sx={{
                    marginBottom: "4px",
                    opacity: 0.7,
                    lineHeight: 1,
                  }}
                >
                  {order.partner.name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 600,
                    lineHeight: 1.1,
                  }}
                >
                  {order.orderId!}
                </Typography>
              </div>
              <div>
                <IconButton
                  size="small"
                  onClick={() => {
                    orderDrawer.show({
                      order,
                    });
                  }}
                >
                  <VisibilityIcon sx={{ fontSize: "inherit" }} />
                </IconButton>
                <CopyToClipboardButton
                  text={order._id}
                  style={{
                    fontSize: "inherit",
                  }}
                />
              </div>
            </div>
            <div className={styles.InfoCardBody}>
              {order.createdAt ? (
                <Data condensed>
                  <Data.Item>
                    <Data.Label>Created</Data.Label>
                    <Data.Content>{formatDate(order.createdAt)}</Data.Content>
                  </Data.Item>
                </Data>
              ) : null}
              {order.summary.formattedRetailPrice ? (
                <Data condensed>
                  <Data.Item>
                    <Data.Label>Retail price</Data.Label>
                    <Data.Content>
                      {order.summary.formattedRetailPrice}
                    </Data.Content>
                  </Data.Item>
                </Data>
              ) : null}
              {order.summary.formattedPriceToPay ? (
                <Data condensed>
                  <Data.Item>
                    <Data.Label>Price to pay</Data.Label>
                    <Data.Content>
                      {order.summary.formattedPriceToPay}
                    </Data.Content>
                  </Data.Item>
                </Data>
              ) : null}
            </div>
          </div>
        </div>
      );
    })
  ) : (
    <div>No orders</div>
  );
};
