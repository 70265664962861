import NotInterestedIcon from "@mui/icons-material/NotInterested";
import Button from "@mui/material/Button";

import UPDialog from "@/components/UPDialog";

import type { UseMutationResult } from "@tanstack/react-query";

export const ApplicationCancelDialog = function ApplicationCancelDialog({
  cancelMutation,
  close,
  isOpen,
}: {
  cancelMutation: UseMutationResult<unknown, Error, void, unknown>;
  close: () => void;
  isOpen: boolean;
}) {
  return (
    <UPDialog
      open={isOpen}
      close={close}
      Icon={NotInterestedIcon}
      Title="Are you sure you want to cancel this application? You cannot undo this action."
      Actions={
        <>
          <Button color="secondary" onClick={close} variant="outlined">
            Never mind
          </Button>
          <Button
            color="error"
            onClick={() => {
              cancelMutation.mutate();
            }}
            variant="contained"
            loading={cancelMutation.isPending}
          >
            Yes, Cancel Application
          </Button>
        </>
      }
    />
  );
};
