import { useMutation } from "@tanstack/react-query";

import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

export default function Unassign({
  onError,
  onSuccess,
  unassign,
  studentCount,
}) {
  const unassignMutation = useMutation({
    mutationFn: unassign,
    onSuccess,
    onError,
  });

  return studentCount !== 0 ? (
    <Tooltip
      title={
        studentCount > 0
          ? "Students must be moved to a new section before removing mentor"
          : "Cannot fetch section data from Canvas"
      }
    >
      <div>
        <Button disabled variant="contained" color="error">
          Remove
        </Button>
      </div>
    </Tooltip>
  ) : (
    <Button
      disabled={studentCount > 0}
      loading={unassignMutation.isPending}
      onClick={() => {
        unassignMutation.mutate();
      }}
      variant="contained"
      color="error"
    >
      Remove
    </Button>
  );
}
