import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Button from "@mui/material/Button";

import { useAlert } from "@/hooks/use-alert";
import Enrollment from "@/models/enrollment";

export default function Mentorswitch({
  enrollment,
  email,
  partnerId,
  inventoryId,
  section,
  courseSections,
}) {
  const showAlert = useAlert();
  const [open, setOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const switchMutation = useMutation({
    mutationFn(data) {
      return Enrollment.switchLmsSections(
        { partnerId, inventoryId, email },
        data,
      );
    },
    onSuccess() {
      courseSections.refetch();
      setOpen(false);
    },
    onError(err) {
      showAlert({
        message: "An Error Has Occurred",
        severity: "error",
      });
      console.log(err);
    },
  });
  const availableSections = courseSections.data.length
    ? courseSections.data.filter((s) => s.id !== section.id)
    : [];

  return (
    <>
      <Button variant="contained" size="small" onClick={() => setOpen(true)}>
        Switch Mentor
      </Button>
      <Dialog open={open}>
        <DialogTitle>Choose a new Mentor</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="mentor-select-label">Mentor:</InputLabel>
            <Select
              labelId="mentor-select-label"
              id="mentor-select"
              value={selectedSection || ""}
              label="Mentor"
              onChange={(e) => setSelectedSection(e.target.value)}
            >
              <MenuItem></MenuItem>
              {availableSections.map((m) => (
                <MenuItem key={m.name} value={m}>
                  {m.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            loading={switchMutation.isPending}
            variant="contained"
            onClick={() =>
              switchMutation.mutate({ ...enrollment, section: selectedSection })
            }
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
