import { refreshToken } from "@kaplan-labs/up-auth-api-client";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useRef, useState } from "react";
import useWebSocket from "react-use-websocket";

import { useSelectedModel } from "@/context/SelectedModelContext";
import Chat from "@/models/chat";
import { CHAT_ROLE } from "@/models/chat/constants";

import {
  itemIsChatErrorMessage,
  itemIsExpiredAuthErrorMessage,
} from "./helpers";

import type { TChatMessage } from "@/models/chat/types";

const WS_URL = import.meta.env.VITE_WS_CHAT_URL;

const generateWsQueryParams = () => {
  return {
    Auth: sessionStorage.getItem("it") ?? "",
  };
};

export const useMason = (
  newMessageCallback: (conversationId: string) => void,
) => {
  const queryClient = useQueryClient();
  const { selectedModel } = useSelectedModel();
  const tempNewChat = useRef<string>("");
  const lastAttemptedMessage = useRef<
    Pick<TChatMessage, "content" | "conversationId" | "modelId"> | undefined
  >(undefined);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [wsQueryParams, setWsQueryParams] = useState<{
    Auth: string;
  }>(generateWsQueryParams());

  const saveToCache = useCallback(
    (conversationId: string, message: TChatMessage) => {
      queryClient.setQueryData(
        Chat.conversation.queryOptions(conversationId)["queryKey"],
        (oldData) => {
          return [...(oldData ?? []), message];
        },
      );
    },
    [queryClient],
  );

  const { sendJsonMessage, lastJsonMessage } = useWebSocket(WS_URL, {
    share: true,
    shouldReconnect: () => true,
    queryParams: wsQueryParams,
    onError() {
      setError("An error occurred");
      setWaitingForResponse(false);
    },
    onMessage(event) {
      const data = JSON.parse(event.data);

      // ERROR: auth
      if (itemIsExpiredAuthErrorMessage(data)) {
        handleAuthError();
        return;
      }

      // ERROR: chat
      if (itemIsChatErrorMessage(data)) {
        setError(data.error.message);
        setWaitingForResponse(false);
        return;
      }

      // SUCCESS: new chat
      if (tempNewChat.current) {
        newMessageCallback(data.conversationId);
        tempNewChat.current = "";
      }

      // SUCCESS: cleanup
      if (lastAttemptedMessage.current) {
        lastAttemptedMessage.current = undefined;
      }
      setError(null);
      setWaitingForResponse(false);

      // SUCCESS: save to cache
      saveToCache(data.conversationId, {
        ...data,
        createdAt: data.createdAt ?? new Date().toISOString(),
        chatId: data.chatId ?? crypto.randomUUID(),
      });
    },
  });

  const sendMessage = useCallback(
    (conversationId: string, message: string) => {
      if (message.length === 0) {
        return;
      }

      setWaitingForResponse(true);
      lastAttemptedMessage.current = {
        content: message,
        conversationId,
        modelId: selectedModel,
      };

      sendJsonMessage({
        content: message,
        conversationId,
        modelId: selectedModel,
        route: "chat",
      });

      saveToCache(conversationId, {
        chatId: crypto.randomUUID(),
        content: message,
        conversationId,
        createdAt: new Date().toISOString(),
        role: CHAT_ROLE.USER,
      });
    },
    [selectedModel, sendJsonMessage, saveToCache],
  );

  const handleAuthError = useCallback(() => {
    const go = async () => {
      await refreshToken(window.auth.client);
      setWsQueryParams(generateWsQueryParams());
      if (lastAttemptedMessage.current) {
        sendMessage(
          lastAttemptedMessage.current.conversationId,
          lastAttemptedMessage.current.content,
        );
      }
    };

    go();
  }, [sendMessage]);

  const sendMessageAsNewChat = useCallback(
    (message) => {
      setWaitingForResponse(true);

      tempNewChat.current = message;

      sendJsonMessage({
        content: message,
        modelId: selectedModel,
        route: "chat",
      });
    },
    [selectedModel, sendJsonMessage],
  );

  return {
    error,
    sendMessage,
    lastJsonMessage,
    sendMessageAsNewChat,
    waitingForResponse,
  };
};
