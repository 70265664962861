import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import Typography from "@mui/material/Typography";

import { Data } from "@/components/Data";

export const Forgotten = function Forgotten() {
  return (
    <>
      <div className="stamp stamp--red">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <EmojiPeopleIcon
            sx={{
              fontSize: "4rem",
              mr: 1.5,
            }}
          />
          <div>
            Forgotten
            <br />
            Contact
          </div>
        </div>
      </div>
      <div
        style={{
          opacity: 0.25,
        }}
      >
        <Typography variant="h4" component="h2">
          <strong>•••••••••• •••••••</strong>
          <br />
          •••••••@•••••.com
          <br />
          •••-•••-••••
        </Typography>
        <Data>
          <Data.Item>
            <Data.Label sx={{ alignSelf: "flex-start" }}>Address</Data.Label>
            <Data.Content>
              ••• ••••• ••••••
              <br />
              ••••••, ••••••••••
              <br />
              •••••
            </Data.Content>
          </Data.Item>
          <Data.Item>
            <Data.Label>Age</Data.Label>
            <Data.Content>••</Data.Content>
          </Data.Item>
          <Data.Item>
            <Data.Label>TCPA Consent</Data.Label>
            <Data.Content>•••••</Data.Content>
          </Data.Item>
          <Data.Item>
            <Data.Label>Underage Consent</Data.Label>
            <Data.Content>•••••</Data.Content>
          </Data.Item>
          <Data.Item>
            <Data.Label>Cross-partner Marketing Opt-in</Data.Label>
            <Data.Content>•••••</Data.Content>
          </Data.Item>
          <Data.Item>
            <Data.Label>TOU PP Accept</Data.Label>
            <Data.Content>•••••</Data.Content>
          </Data.Item>
        </Data>
      </div>
    </>
  );
};
