import { ApplicationStatus } from "@/models/application/constants";
import { CatalogueItemType } from "@/models/catalogue/constants";
import { CouponAmountType, CouponType } from "@/models/coupon/constants";
import { InventoryStatus } from "@/models/inventory/constants";

import { generateEndOfSeasonDate } from "./lib/helpers";

export const NAVIGATION_DRAWER_WIDTH = 240;
export const MAIN_VIEW_WIDTH = NAVIGATION_DRAWER_WIDTH + 40;

export const FORM_PAPER_SX = {
  marginTop: 1,
  marginRight: 2,
  marginBottom: 2,
  marginLeft: 2,
  px: 2,
  py: 2,
  borderRadius: 2,
  boxShadow: "0 1px 1px rgba(0,0,0, 0.075)",
} as const;

export const FORM_PAPER_HEADING_SX = {
  pl: 4,
  pt: 3.5,
  fontWeight: "600",
} as const;
export const FORM_PAPER_SUB_HEADING_SX = {
  mb: 2,
  pl: 4,
  opacity: 0.75,
} as const;

// 347af6
export const PRIMARY_COLOR_LIGHT = "#9134f6";
export const PRIMARY_COLOR_DARK = "#A14DFF";
export const SECONDARY_COLOR_LIGHT = "#006a99";
export const SECONDARY_COLOR_DARK = "#3C93C6";

export const FONT_FAMILY = [
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",");

export const INVENTORY_TYPE_OPTIONS = [
  { label: "Add-On", value: CatalogueItemType.AddOn },
  { label: "College Credit", value: CatalogueItemType.CollegeCredit },
  { label: "Program", value: CatalogueItemType.Program },
] as const;

export const INVENTORY_TYPE_MAP = {
  [CatalogueItemType.AddOn]: "Add-On",
  [CatalogueItemType.CollegeCredit]: "College Credit",
  [CatalogueItemType.Program]: "Program",
} as const;

export const REFUND_REASONS_OPTIONS = [
  { label: "Cancellation", value: "cancellation" },
  { label: "Illness / Family", value: "illnessFamily" },
  { label: "Make Good / Dissatisfied", value: "makeGood" },
  { label: "No Longer Available", value: "notAvailable" },
  { label: "Opted for In Person", value: "inPerson" },
  { label: "System Issue", value: "systemIssue" },
  { label: "Unauthorized Payment", value: "unauthorizedPayment" },
  { label: "Unknown", value: "unknown" },
  { label: "Other", value: "other" },
] as const;

export const REFUND_REASONS_MAP = {
  cancellation: "Cancellation",
  illnessFamily: "Illness / Family",
  makeGood: "Make Good / Dissatisfied",
  notAvailable: "No Longer Available",
  inPerson: "Opted for In Person",
  systemIssue: "System Issue",
  unauthorizedPayment: "Unauthorized Payment",
  unknown: "Unknown",
  other: "Other",
} as const;

export const STATUS_MAP = {
  userStarted: "user started",
  pendingReview: "pending",
  onHoldCX: "on hold, agent",
  onHoldUser: "on hold, user",
  denied: "denied",
  approved: "approved",
  adminCompleted: "complete",
  abandoned: "abandoned",
} as const;

export const STATUS_COLOR_MAP = {
  userStarted: "grey",
  pendingReview: "grey",
  onHoldCX: "grey",
  onHoldUser: "grey",
  denied: "red",
  approved: "green",
  adminCompleted: "green",
  abandoned: "red",
} as const;

export const SCHOLARSHIP_STATUS_OPTIONS = [
  { label: "Pending", value: "pendingReview" },
  { label: "No action, complete", value: "adminCompleted" },
  { label: "On hold, awaiting agent", value: "onHoldCX" },
  { label: "Approve Now", value: "approved" },
  { label: "On hold, awaiting user", value: "onHoldUser" },
  { label: "Deny Now", value: "denied" },
] as const;

export const ALL_INVENTORY_DURATION_WEEKS = [1, 2, 4, 6, 8, 9, 10, 12] as const;

export const ALL_INVENTORY_DURATION_OPTIONS = ALL_INVENTORY_DURATION_WEEKS.map(
  (item) => {
    if (item === 1) return { value: `${item} week` };
    return { value: `${item} weeks` };
  },
);

export const PROGRAM_INVENTORY_DURATION_WEEKS = [1, 2, 4] as const;

export const PROGRAM_INVENTORY_DURATION_OPTIONS =
  PROGRAM_INVENTORY_DURATION_WEEKS.map((item) => {
    if (item === 1) return { value: `${item} week` };
    return { value: `${item} weeks` };
  });

export const COLLEGECREDIT_INVENTORY_DURATION_WEEKS = [
  6, 8, 9, 10, 12,
] as const;

export const COLLEGECREDIT_INVENTORY_DURATION_OPTIONS =
  COLLEGECREDIT_INVENTORY_DURATION_WEEKS.map((item) => {
    return { value: `${item} weeks` };
  });

export const ADDON_INVENTORY_DURATION_WEEKS = [10] as const;

export const ADDON_INVENTORY_DURATION_OPTIONS =
  ADDON_INVENTORY_DURATION_WEEKS.map((item) => {
    return { value: `${item} weeks` };
  });

export const APPLICATION_STATUS_OPTIONS = [
  { label: "Pending", value: ApplicationStatus.PendingReview },
  { label: "On hold, awaiting agent", value: ApplicationStatus.OnHoldCX },
  { label: "Approve Now", value: ApplicationStatus.Approved },
  { label: "On hold, awaiting user", value: ApplicationStatus.OnHoldUser },
  { label: "Deny Now", value: ApplicationStatus.Denied },
] as const;

export const INVENTORY_STATUS_MAP = {
  [InventoryStatus.PreMarket]: "Pre-Market",
  [InventoryStatus.OnMarketPublic]: "Publicly on Market",
  [InventoryStatus.OnMarketPrivate]: "Privately on Market",
  [InventoryStatus.Onboarding]: "Onboarding",
  [InventoryStatus.Running]: "Running",
  [InventoryStatus.Canceled]: "Canceled",
  [InventoryStatus.Completed]: "Completed",
} as const;

export const INVENTORY_STATUS_OPTIONS = [
  { label: "Pre-Market", value: InventoryStatus.PreMarket },
  { label: "Publicly on Market", value: InventoryStatus.OnMarketPublic },
  { label: "Privately on Market", value: InventoryStatus.OnMarketPrivate },
  { label: "Onboarding", value: InventoryStatus.Onboarding },
  { label: "Running", value: InventoryStatus.Running },
  { label: "Canceled", value: InventoryStatus.Canceled },
  { label: "Completed", value: InventoryStatus.Completed },
] as const;

export const INVENTORY_STATUS_COLOR_MAP = {
  [InventoryStatus.PreMarket]: "grey",
  [InventoryStatus.OnMarketPublic]: "green",
  [InventoryStatus.OnMarketPrivate]: "green",
  [InventoryStatus.Onboarding]: "grey",
  [InventoryStatus.Running]: "grey",
  [InventoryStatus.Canceled]: "red",
  [InventoryStatus.Completed]: "grey",
} as const;

//Does not include partner or scopedUsers
export const PS_COUPON_DEFAULTS = {
  amountType: CouponAmountType.Percentage,
  applicableToCart: true,
  hasCustomCode: false,
  type: CouponType.PartialScholarship,
  amount: 50,
  maxUses: 10,
  expiration: generateEndOfSeasonDate(),
};

//Does not include partner or scopedUsers
export const FS_COUPON_DEFAULTS = {
  amountType: CouponAmountType.Percentage,
  hasCustomCode: false,
  type: CouponType.FullScholarship,
  amount: 100,
  maxUses: 1,
  expiration: generateEndOfSeasonDate(),
};

export const PROMO_COUPON_DEFAULTS = {
  hasCustomCode: true,
  amountType: CouponAmountType.Percentage,
  amount: 10,
  maxUses: 10,
  expiration: generateEndOfSeasonDate(),
};

export const AGES = [
  {
    value: "13",
    displayValue: "13",
  },
  {
    value: "14",
    displayValue: "14",
  },
  {
    value: "15",
    displayValue: "15",
  },
  {
    value: "16",
    displayValue: "16",
  },
  {
    value: "17",
    displayValue: "17",
  },
  {
    value: "18",
    displayValue: "18+",
  },
];

export const PARTNER_STYLES = [
  {
    _id: "89b88eba-e8ba-4559-ba70-517cbc5279c1",
    color: "#003071",
    logo: "/partner-logos/case.png",
  },
  {
    _id: "85a5ae5a-195c-4757-8e50-a1c2ddebc318",
    color: "#009BDB",
    logo: "/partner-logos/cbs.png",
  },
  {
    _id: "4d3d2f56-d391-4f5f-bbad-1fafbc2cc9f7",
    color: "#00693e",
    logo: "/partner-logos/dartmouth.png",
  },
  {
    _id: "709eedce-7712-484a-b1d8-d37ca530599f",
    color: "#003DA5",
    logo: "/partner-logos/georgetown.png",
  },
  {
    _id: "350b4ba2-d50c-4d3d-beae-bc0fdc4709a5",
    color: "#A51C30",
    logo: "/partner-logos/harvard.png",
  },
  {
    _id: "eb6174a9-b971-4d92-82eb-a0872dcfed6c",
    color: "#4E2A84",
    logo: "/partner-logos/northwestern.png",
  },
  {
    _id: "d7e70d6d-ee5a-4ee7-bf9f-38370c0b2316",
    color: "#D03B29",
    logo: "/partner-logos/parsons.png",
  },
  {
    _id: "c7c0514c-3aee-4b85-a698-7d524ca164e7",
    color: "rgb(36, 15, 110)",
    logo: "/partner-logos/prelum.png",
  },
  {
    _id: "1e26f6ca-c86c-4dce-9815-659cb8e47b00",
    color: "rgb(0, 32, 91)",
    logo: "/partner-logos/rice.png",
  },
  {
    _id: "d00f0f2b-e400-4648-b2b5-cf7b5622c575",
    color: "#AE9142",
    logo: "/partner-logos/notre-dame.png",
  },
  {
    _id: "87a16c77-11e3-4333-bbe7-a9e61d8abf79",
    color: "#00467F",
    logo: "/partner-logos/rochester.png",
  },
  {
    _id: "459ece1e-541b-4f60-a192-d7b38a44e0b2",
    color: "rgb(140, 109, 44)",
    logo: "/partner-logos/wake.png",
  },
  {
    _id: "89e7ff0d-8151-4ce5-8a36-15dbf4124415",
    color: "rgb(17, 87, 64)",
    logo: "/partner-logos/wm.png",
  },
] as const;

export const JourneyStage = {
  PreApp: "Pre-App",
  AppStarted: "App Started",
  AppPending: "App Pending",
  Approved: "Approved",
  Enrolled: "Enrolled",
  Active: "Active",
  Alum: "Alum",
} as const;
