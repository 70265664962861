import { Link, useMatch, useResolvedPath } from "react-router";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import clientRoutes from "@/lib/client-routes";

const ListItemSX = { borderRadius: 2, paddingLeft: 2 };

const Button = function ({ partner }) {
  const to = clientRoutes.partners.show(partner._id);
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <li>
      <ListItemButton
        className={match ? "Mui-selected" : ""}
        component={Link}
        to={to}
        state={{ partner }}
        sx={ListItemSX}
      >
        <ListItemText
          sx={{
            my: 0,
          }}
          primary={partner.name}
          slotProps={{
            primary: {
              variant: "body2",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          }}
        />
      </ListItemButton>
    </li>
  );
};

const MainList = function MainList({ partners }) {
  return (
    <List sx={{ paddingTop: 1, paddingRight: "6px", paddingLeft: "6px" }}>
      {partners.map((p) => (
        <Button key={p._id} partner={p} />
      ))}
    </List>
  );
};

export default MainList;
