import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid2";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";

import EmptyBox from "@/components/EmptyBoxField/EmptyBox";
import FormErrorMessage from "@/components/FormErrorMessage";
import { useCatalogueForPartner } from "@/models/catalogue/hooks";
import Mentor from "@/models/mentor";

import type { TCatalogueItem } from "@/models/catalogue/types";
import type { TMentorQualifiedCatalogueItem } from "@/models/mentor/types";
import type { UseQueryResult } from "@tanstack/react-query";
import type { FieldError } from "react-hook-form";

type Props = {
  email: string;
  partners: UseQueryResult<Array<{ _id: string; name: string }>>;
  save: (items: TMentorQualifiedCatalogueItem[]) => void;
  usedItems: string[];
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const AddCatalogueItems = function AddCatalogueItems(props: Props) {
  const [didError, setDidError] = useState(false);

  const saveMutation = useMutation({
    mutationFn(
      items: TCatalogueItem[],
    ): Promise<TMentorQualifiedCatalogueItem[]> {
      return Promise.all(
        items.map((item) => {
          return Mentor.addQualifiedCatalogueItems({
            data: item,
            email: props.email,
          });
        }),
      );
    },
    onSuccess(items) {
      setDidError(false);
      props.save(items);
      setValue("catalogueItems", [], {
        shouldDirty: false,
        shouldTouch: false,
      });
    },
    onError() {
      setDidError(true);
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<{
    catalogueItems: TCatalogueItem[];
    partnerID: string;
  }>({
    defaultValues: {
      partnerID: "",
      catalogueItems: [],
    },
  });
  const selectedPartner = watch("partnerID");
  const catalogueItems = useCatalogueForPartner(selectedPartner);

  return (
    <form
      onSubmit={handleSubmit(
        (data: { partnerID: string; catalogueItems: TCatalogueItem[] }) => {
          if (!data || !catalogueItems.data) {
            return;
          }
          saveMutation.mutate(data.catalogueItems);
        },
      )}
      noValidate
    >
      <Grid container spacing={2}>
        <Grid size={12}>
          <FormControl fullWidth>
            {props.partners.isLoading ? (
              <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                <CircularProgress size="1.5rem" sx={{ mr: 1 }} />
                <Typography variant="body2">Loading Partners...</Typography>
              </Box>
            ) : (
              <>
                <Controller
                  control={control}
                  name="partnerID"
                  rules={{ required: "This field is required" }}
                  render={({ field }) => {
                    const { onChange, value, ...rest } = field;
                    return (
                      <TextField
                        {...rest}
                        disabled={saveMutation.isPending}
                        error={Boolean(errors.partnerID)}
                        label="Partner"
                        onChange={(e) => {
                          onChange(e.target.value);
                          setValue("catalogueItems", [], {
                            shouldDirty: true,
                            shouldTouch: true,
                          });
                        }}
                        required
                        select
                        value={value ?? ""}
                        slotProps={{
                          inputLabel: { shrink: true },
                        }}
                      >
                        {props.partners.data.map((partner) => {
                          return (
                            <MenuItem key={partner._id} value={partner._id}>
                              {partner.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    );
                  }}
                />
              </>
            )}
            {errors.partnerID ? (
              <FormErrorMessage error={errors.partnerID} />
            ) : null}
          </FormControl>
        </Grid>

        <Grid size={12}>
          <FormControl fullWidth error={Boolean(errors.catalogueItems)}>
            {catalogueItems.isLoading ? (
              <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
                <CircularProgress size="1.5rem" sx={{ mr: 1 }} />
                <Typography variant="body2">
                  Loading catalogue items...
                </Typography>
              </Box>
            ) : catalogueItems.data ? (
              <>
                <Controller
                  control={control}
                  name="catalogueItems"
                  rules={{ required: "This field is required" }}
                  render={({ field }) => {
                    const { onChange, value, ...rest } = field;
                    return (
                      <>
                        <Autocomplete
                          {...rest}
                          value={value}
                          disableCloseOnSelect
                          disabled={
                            !catalogueItems.data || saveMutation.isPending
                          }
                          getOptionLabel={(item) => item.name}
                          multiple
                          options={catalogueItems.data.filter((item) => {
                            return props.usedItems.includes(item._id) === false;
                          })}
                          onChange={(e, newValue) => {
                            onChange(newValue);
                          }}
                          size="small"
                          isOptionEqualToValue={(option, value) => {
                            return option._id === value._id;
                          }}
                          renderOption={(props, option, state) => {
                            return (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={state.selected}
                                  size="small"
                                />
                                {option.name}
                              </li>
                            );
                          }}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                required
                                label="Catalogue items"
                                placeholder="Catalogue items"
                                size="small"
                              />
                            );
                          }}
                        />
                      </>
                    );
                  }}
                />
              </>
            ) : (
              <EmptyBox sx={{ py: 0.75 }}>&nbsp;</EmptyBox>
            )}
            <Box
              sx={{ display: "flex", alignItems: "center", flexGrow: 1, pt: 1 }}
            >
              {didError ? (
                <FormErrorMessage text="An error occurred while saving" />
              ) : null}
            </Box>
            {errors.catalogueItems ? (
              <FormErrorMessage error={errors.catalogueItems as FieldError} />
            ) : null}
          </FormControl>
        </Grid>

        <Grid size={12}>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                color="primary"
                loading={saveMutation.isPending}
                type="submit"
                variant="contained"
              >
                Add
              </Button>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
