import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Box from "@mui/system/Box";

export default function Assign({ mentors, isSaving, submit }) {
  const [selectedMentorEmail, setSelectedMentorEmail] = useState("0");

  useEffect(() => {
    setSelectedMentorEmail("0");
  }, [isSaving]);

  return (
    <Stack
      as="form"
      direction="row"
      spacing={1}
      onSubmit={(e) => {
        e.preventDefault();
        submit(selectedMentorEmail);
      }}
    >
      <Select
        disabled={isSaving}
        name="email"
        onChange={(e) => setSelectedMentorEmail(e.target.value)}
        sx={{ width: "100%" }}
        value={selectedMentorEmail}
      >
        <MenuItem disabled value="0">
          {mentors?.length ? "Choose a mentor" : "No available mentors"}
        </MenuItem>
        {mentors?.map((mentor) => {
          return (
            <MenuItem key={mentor._id} value={mentor.email}>
              {mentor.firstName} {mentor.lastName}
            </MenuItem>
          );
        })}
      </Select>
      <Box sx={{ pr: 0.5 }}>
        <Button
          color="primary"
          loading={isSaving}
          sx={{
            marginRight: 1,
            minWidth: "7rem",
          }}
          type="submit"
          variant="contained"
        >
          Assign
        </Button>
      </Box>
    </Stack>
  );
}
