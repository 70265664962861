import { Accordion as AccordionBase } from "@base-ui-components/react/accordion";
import { AnimatePresence, motion } from "framer-motion";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import styles from "./index.module.css";

import type { PropsWithChildren } from "react";

export const AccordionRoot = function ({
  children,
  className,
  defaultValue,
  theme = "default",
  variant = "default",
}: PropsWithChildren<{
  className?: string;
  defaultValue?: Array<number>;
  theme?: "default" | "red";
  variant?: "default" | "outlined";
}>) {
  return (
    <AccordionBase.Root
      className={className ?? styles.Accordion}
      data-theme={theme}
      data-variant={variant}
      defaultValue={defaultValue}
    >
      {children}
    </AccordionBase.Root>
  );
};

export const AccordionItem = function ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <AccordionBase.Item className={className ?? styles.Item}>
      {children}
    </AccordionBase.Item>
  );
};

export const AccordionHeader = function ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <AccordionBase.Header className={className ?? styles.Header}>
      {children}
    </AccordionBase.Header>
  );
};

export const AccordionTrigger = function ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <AccordionBase.Trigger className={className ?? styles.Trigger}>
      {children}
      <div className={styles.TriggerIcon}>
        <ArrowForwardIosIcon
          style={{
            opacity: 0.75,
            fontSize: "13px",
          }}
        />
      </div>
    </AccordionBase.Trigger>
  );
};

export const AccordionPanel = function ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <AccordionBase.Panel
      render={(props, state) => {
        return (
          <AnimatePresence>
            {state.open ? (
              <motion.div
                className={className ?? styles.Panel}
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ ease: "easeInOut", duration: 0.15 }}
              >
                {children}
              </motion.div>
            ) : null}
          </AnimatePresence>
        );
      }}
    />
  );
};

export const AccordionContent = function ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return <div className={`${className ?? styles.Content}`}>{children}</div>;
};

export const Accordion = {
  Root: AccordionRoot,
  Item: AccordionItem,
  Header: AccordionHeader,
  Trigger: AccordionTrigger,
  Panel: AccordionPanel,
  Content: AccordionContent,
};
