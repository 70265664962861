import HandymanIcon from "@mui/icons-material/Handyman";

export const WIPBanner = function () {
  return (
    <div className="wip-banner">
      <HandymanIcon sx={{ fontSize: "18px" }} />
      Under Construction
      <HandymanIcon sx={{ fontSize: "18px" }} />
    </div>
  );
};
