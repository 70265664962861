import { useQuery } from "@tanstack/react-query";

import Enrollment from "@/models/enrollment";
import EnrollmentsList from "@/routes/Profiles/components/EnrollmentsList";

export const EnrollmentsView = function ({
  partnerId,
  email,
}: {
  partnerId: string;
  email: string;
}) {
  const enrollmentsList = useQuery({
    queryKey: Enrollment.findByUser.queryKey(partnerId, email),
    queryFn() {
      return Enrollment.findByUser.queryFn(partnerId, email);
    },
  });

  return <EnrollmentsList enrollmentsList={enrollmentsList} email={email} />;
};
