import List from "@mui/material/List";
import { styled } from "@mui/material/styles";

export const NavList = styled(List)(({ theme }) => ({
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  paddingRight: "6px",
  paddingLeft: "6px",
  "& .MuiListItemButton-root:after": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: 8,
    background: "#e2ddee",
    zIndex: 1,
    opacity: 0,
    transform: "scale(0.8)",
    transition:
      "transform 200ms cubic-bezier(0.345, 0.115, 0.135, 1.42), opacity 150ms ease-out",
    ...theme.applyStyles("dark", {
      background: "#363044",
    }),
  },
  "& .MuiListItemButton-root": {
    color: theme.palette.text.primary,
    borderRadius: 8,
    paddingTop: 3,
    paddingBottom: 4,
  },
  "& .MuiListItemButton-root.sub-nav": {
    paddingTop: 0,
    paddingBottom: 1,
  },
  "& .MuiListItemButton-root > *": {
    zIndex: 2,
  },
  "& .MuiListItemButton-root.Mui-selected:after": {
    transform: "scale(1)",
    opacity: 1,
  },
  "& .MuiListItemButton-root.Mui-selected": {
    backgroundColor: "transparent",
  },
  "& .MuiListItemButton-root:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1.35rem",
  },
  "& .MuiListSubheader-root": {
    paddingTop: "4px",
    lineHeight: "28px",
    backgroundColor: "transparent",
  },
}));
