import Box from "@mui/material/Box";

import { Drawer, DrawerManager } from "@/components/Drawer";
import Cart from "@/routes/Profiles/components/Cart";

import type { TProfile } from "@/models/profile/types";

export const CartDrawer = DrawerManager.create(function ({
  profile,
}: {
  profile: TProfile;
}) {
  return (
    <Drawer
      title="Live Cart"
      supTitle={`${profile.partner.name} - ${profile.email}`}
    >
      <Box sx={{ p: 2, bgcolor: "background.paper" }}>
        <Cart profile={profile} />
      </Box>
    </Drawer>
  );
});
