import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import CssBaseline from "@mui/material/CssBaseline";

import "./theme.css";
import "./index.css";
import AlertProvider from "@/context/AlertContext";

import App from "./App";
import { authSetup } from "./setup";
import ThemeSetup from "./ThemeSetup";

import type { Auth } from "@kaplan-labs/up-auth-api-client";
import type { HTTPError } from "ky";

declare global {
  interface Window {
    auth: Auth;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error: HTTPError) => {
        return error.response?.status !== 404;
      },
    },
  },
});

authSetup();

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <StrictMode>
    <CssBaseline />
    <QueryClientProvider client={queryClient}>
      <ThemeSetup>
        <AlertProvider>
          <App />
        </AlertProvider>
      </ThemeSetup>
    </QueryClientProvider>
  </StrictMode>,
);
