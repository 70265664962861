import { useState } from "react";

import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import UPDialog from "@/components/UPDialog";

export default function ConfirmCancel({ handleClose, mutation }) {
  const [confirmed, setConfirmed] = useState(false);

  return (
    <UPDialog
      close={handleClose}
      Content={
        <>
          <div>You cannot undo this action.</div>

          <Box sx={{ textAlign: "center" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmed}
                  color="primary"
                  name="conform"
                  onChange={(e) => setConfirmed(e.target.checked)}
                />
              }
              label="Yes, I am sure"
              labelPlacement="end"
            />
          </Box>
        </>
      }
      Icon={DoDisturbIcon}
      Title="Are you sure you want to permanently drop this enrollment?"
      Actions={
        <>
          <Button color="neutral" onClick={handleClose} variant="outlined">
            Never mind
          </Button>
          <Button
            color="error"
            loading={mutation.isPending}
            disabled={!confirmed}
            onClick={() => {
              mutation.mutate();
            }}
            variant="contained"
          >
            Drop enrollment
          </Button>
        </>
      }
    />
  );
}
