import Coupon from "./Coupon";

export const CouponsView = function ({ contactIds }: { contactIds: string[] }) {
  return contactIds?.length ? (
    contactIds.map((c) => {
      return <Coupon key={c} couponId={c} />;
    })
  ) : (
    <div>No coupons</div>
  );
};
