import { useMutation } from "@tanstack/react-query";

import { Drawer, DrawerManager } from "@/components/Drawer";
import Contact from "@/models/contact";
import ContactForm from "@/routes/Contacts/components/ContactForm";

import type { TContact } from "@/models/contact/types";

export default DrawerManager.create(function ({
  cancel,
  data,
  errorCallback,
  successCallback,
}: {
  cancel: () => void;
  data?: TContact;
  errorCallback?: (error: unknown) => void;
  successCallback?: (data: unknown) => void;
}) {
  const EXISTING_CONTACT = Boolean(data?._id);

  const mutation = useMutation({
    mutationFn(data: TContact) {
      return Contact.save(data);
    },
    onSuccess(data) {
      if (successCallback) {
        successCallback(data);
      }
    },
    onError(error) {
      if (errorCallback) {
        errorCallback(error);
      }
    },
  });

  return (
    <Drawer
      loading={mutation.isPending}
      title={`${EXISTING_CONTACT ? "Edit" : "New"} Contact`}
    >
      <ContactForm
        cancel={cancel}
        contactData={data}
        isNewContact={!EXISTING_CONTACT}
        isSaving={mutation.isPending}
        isError={mutation.isError}
        onSubmit={mutation.mutate}
      />
    </Drawer>
  );
});
